.stick-footer {
    #wrapper {
      min-height: 100%;
      margin: 0 auto -190px;
    }
    #push, #footer-wrapper {
      height: 190px;
    }
}
#footer-wrapper {
    background-color: #eee;
}
#footer {
    padding: 30px 0;
    a {
        letter-spacing: -0.2px;
        font-weight: 300;
        padding: 7px 15px;
    }
    p {
        font-size:1.2rem;
    }
    strong {
        font-weight: 500;
    }
    h5 {
        color: $color-main;
    }
    .menu {
        overflow:hidden;
        li {
            display:inline-block;
            width:50%;
            @media (max-width: 991px) {
                &:nth-child(2n + 1) {
                    &:after{
                        clear: both;
                    }
                }
            }
            @media (max-width: 767px) {
                display: block;
                width:100%;
            }
            float:left;
            a:before {
                content: "\f105";
                font-family: fontawesome;
                padding-right: 5px;
                color: rgb(194, 38, 121);
            }
        }
    }
    .moduletable {
        .module_container {
            padding-right:30px;
            .custom {
                @media (max-width: 767px) {
                    text-align: center;
                    margin-top: 30px;
                }
            }
        }
        &:after {
            content: '';
            width: 1px;
            height: 100%;
            background-color: white;
            display: block;
            position: absolute;
            right: 10px;
            top: 0;
        }
        &:nth-last-child(1) {
            &:after {
                display: none;
            }
        }
    }
    .module_container {
        padding-right:30px;
    }
    .moduletable:after {
        content: '';
        width: 1px;
        height: 100%;
        background-color: white;
        display: block;
        position: absolute;
        right: 10px;
        top: 0;
    }

}