#aside-left {
    a.openmenu {
        font-size: 16px;
        width: 50px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        line-height: 32px;
        text-align: center;
        z-index: 1;
        &:before {
            display: none;
        }
    }
    #ordensmenu {
        .m148 {
            > .title {
                a.openmenu {
                    line-height: 40px;
                    padding: 0px;
                }
            }
        }
    }
    a[aria-expanded="true"] i:before {
        content: "\f107";
    }
    a[aria-expanded="false"] i:before {
        content: "\f105";
    }
    .moduletable.llc-menu {
        margin-bottom: 20px;
        > .module_container {
            padding: 0px;
            .llc {
                .nav {
                    padding: 0px;
                    margin: 0px;
                    li {
                        margin: 0px;
                        &:before {
                            display: none;
                        }
                        .title {
                            position: relative;
                            padding: 0px;
                            margin: 0px;
                            a {
                                display: block;
                                padding: 6px 20px;
                                margin: 0px;
                                line-height: 2rem;
                                &:hover {
                                    font-weight: 600;
                                }
                                &:before{
                                    content: '\e5cd';
                                    font-family:'Streamline';
                                    font-size:0.7rem;
                                    margin-right: 12px;
                                    margin-left: -6px;
                                    vertical-align:bottom;
                                    line-height:2rem;
                                    color: white;
                                }
                                &.lock {
                                    &:before {
                                        display: inline-block;
                                        font-family: 'FontAwesome';
                                        font-size: inherit;
                                        text-rendering: auto;
                                        -webkit-font-smoothing: antialiased;
                                        -moz-osx-font-smoothing: grayscale;
                                        content: "\f023";
                                        color: white;
                                        font-size: 1.6rem;
                                        margin-right: 12px;
                                        margin-left: -9px;
                                    }
                                }
                                &.unlock {
                                    &:before {
                                        display: inline-block;
                                        font-family: 'FontAwesome';
                                        font-size: inherit;
                                        text-rendering: auto;
                                        -webkit-font-smoothing: antialiased;
                                        -moz-osx-font-smoothing: grayscale;
                                        content: "\f13e";
                                        color: white;
                                        font-size: 1.6rem;
                                        margin-right: 12px;
                                        margin-left: -9px;
                                    }
                                }
                            }
                            .separator {
                                &.withchilds {
                                    display: block;
                                    padding: 6px 20px;
                                    margin: 0px;
                                    line-height: 2rem;
                                    &:hover {
                                        font-weight: 600;
                                    }
                                    &:before{
                                        content: '\e5cd';
                                        font-family:'Streamline';
                                        font-size:0.7rem;
                                        margin-right: 12px;
                                        margin-left: -6px;
                                        vertical-align:bottom;
                                        line-height:2rem;
                                        color: white;
                                    }
                                }
                            }
                        }
                        ul {
                            padding: 0px 0px 0px 20px;
                            margin: 0px;
                        }
                    }

                }
                &.default {
                    .nav {
                        background-color:$llc-default-main;
                        > li {
                            &:hover {
                                > .title {
                                    background-color: $llc-default-second;
                                }
                            }
                            &.current {
                                > .title {
                                    background-color: $llc-default-second;
                                }
                            }
                            ul {
                                li {
                                    &:hover {
                                        > .title {
                                            background-color: $llc-default-main;
                                        }
                                    }
                                    &.active {
                                        > .title {
                                            font-weight: 600;
                                        }
                                    }
                                }
                            }
                            
                            &:nth-last-child(1) {
                                > .title {
                                    background-color: $llc-default-third;
                                    a {
                                        padding: 10px 20px;
                                    }
                                }
                            }
                        }
                    }
                    .ordenen {
                        .nav {
                            > li {
                                &:nth-last-child(2) {
                                    > .title {
                                        background-color: $llc-default-fourth;
                                        a {
                                            padding: 10px 20px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    
                    .c21 {
                        .nav {
                            > li {
                                &:nth-last-child(1) {
                                    > .title {
                                        background-color: inherit;
                                        a {
                                            display: block;
                                            padding: 6px 20px;
                                            margin: 0px;
                                            line-height: 2rem;
                                            &:hover {
                                                font-weight: 600;
                                            }
                                            &:before{
                                                content: '\e5cd';
                                                font-family:'Streamline';
                                                font-size:0.7rem;
                                                margin-right: 12px;
                                                margin-left: -6px;
                                                vertical-align:bottom;
                                                line-height:2rem;
                                                color: white;
                                            }
                                        }
                                        
                                    }
                                }
                                
                                &:hover {
                                    > .title {
                                        background-color: $llc-default-second;
                                    }
                                }
                            }
                        }
                    }
                }
                &.second {
                    .nav {
                        background-color:$llc-second-main;
                        > li {
                            &:hover {
                                > .title {
                                    background-color: $llc-second-second;
                                }
                            }
                            &.current {
                                > .title {
                                    background-color: $llc-second-second;
                                }
                            }
                            ul {
                                li {
                                    &:hover {
                                        > .title {
                                            background-color: $llc-second-main;
                                        }
                                    }
                                    &.active {
                                        > .title {
                                            font-weight: 600;
                                        }
                                    }
                                }
                            }
                            
                            &:nth-last-child(1) {
                                > .title {
                                    background-color: $llc-second-third;
                                    a {
                                        padding: 10px 20px;
                                    }
                                }
                            }
                        }
                    }
                    .ordenen {
                        .nav {
                            > li {
                                &:nth-last-child(2) {
                                    > .title {
                                        background-color: $llc-second-fourth;
                                        a {
                                            padding: 10px 20px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.third {
                    .nav {
                        background-color:$llc-third-main;
                        > li {
                            &:hover {
                                > .title {
                                    background-color: $llc-third-second;
                                }
                            }
                            &.current {
                                > .title {
                                    background-color: $llc-third-second;
                                }
                            }
                            ul {
                                li {
                                    &:hover {
                                        > .title {
                                            background-color: $llc-third-main;
                                        }
                                    }
                                    &.active {
                                        > .title {
                                            font-weight: 600;
                                        }
                                    }
                                }
                            }
                            
                            &:nth-last-child(1) {
                                > .title {
                                    background-color: $llc-third-third;
                                    a {
                                        padding: 10px 20px;
                                    }
                                }
                            }
                        }
                    }
                    .ordenen {
                        .nav {
                            > li {
                                &:nth-last-child(2) {
                                    > .title {
                                        background-color: $llc-third-fourth;
                                        a {
                                            padding: 10px 20px;
                                        }
                                    }
                                }
                            }
                        }
                    }
//                    .c20 {
//                        .nav {
//                            > li {
//                                &:nth-last-child(1) {
//                                    > .title {
//                                        background-color: inherit;
//                                        a {
//                                            display: block;
//                                            padding: 6px 20px;
//                                            margin: 0px;
//                                            line-height: 2rem;
//                                            &:hover {
//                                                font-weight: 600;
//                                            }
//                                            &:before{
//                                                content: '\e5cd';
//                                                font-family:'Streamline';
//                                                font-size:0.7rem;
//                                                margin-right: 12px;
//                                                margin-left: -6px;
//                                                vertical-align:bottom;
//                                                line-height:2rem;
//                                                color: white;
//                                            }
//                                        }
//                                        
//                                    }
//                                }
//                                
//                                &:hover {
//                                    > .title {
//                                        background-color: $llc-third-second;
//                                    }
//                                }
//                            }
//                        }
//                    }
                }
            }
            
        }
        
    }
    .moduletable.mod-121 {
        .module_container {
            background-color: white;
            min-height:500px;
            .btn {
                margin-top: 0px;
                font-weight: 600;
                &:after{
                    display: none;
                }
            }
        }
    }
    .mod-111 {
        .module_container {
            strong {
                font-weight: 500;
                font-size: 2.2rem;
            }
        }
    }
    .moduletable {
        &:not(:last-child){
            margin-bottom: 20px;
        }
        .module_container {
            background-color: $color-second;
            padding:20px;
            * {
                color:white;
            }
            h4 {
                font-size: 2.2rem;
                font-weight: 500;
                margin-bottom: 20px;
            }
            .btn {
                width: 100%;
                margin-top: 20px;
                color: black;
                &:after{
                    content: '\e5bf';
                    font-family:'Streamline';
                    font-size:1rem;
                    margin-left:6px;
                    vertical-align:middle;
                    line-height:1.4rem;
                    color: inherit;
                }
                
            }
            ul {
                list-style:none;
                padding:0px;
                li {
                    margin-bottom: 6px;
                    &:before{
                        content: '\e5cd';
                        font-family:'Streamline';
                        font-size:0.7rem;
                        margin-right:6px;
                        vertical-align:bottom;
                        line-height:1.4rem;
                        color: $color-info;
                    }
                    a {
                        padding: inherit;
                        display: inline-block;
                    }
                }
            }
            
        }
    }
    .moduletable.doc {
        .module_container {
            background-color: $color-main;
            header{
                &:before {
                    content: '';
                    background-image: url(/images/document_icon.png);
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 80px;
                    height: 40px;
                    display: block;
                    margin: 0 auto 10px;
                    padding-bottom: 100px;
                    border-bottom: 1px solid #57b4ec;
                }
                h4 {
                    text-align: center;
                    font-size: 3rem;
                    font-weight: 400;
                    margin-bottom: 0px;
                }
            } 
        }
    }
}

#aside-right {
    .moduletable{
        &:not(:last-child){
            margin-bottom: 20px;
        }
    }
    .vertical-boxes .boks {
        background-color: #fff;
        text-align: center;
        padding: 30px 20px;
        
        p {
            font-size: 16px;
            
            strong {
                font-size: 22px;
                font-weight: 500;
            }
        }
        + .boks {
            margin-top: 1px;
        }
    }

}