#logo {
    z-index: 2;
    position: relative;
    padding-bottom: 10px;
    .logo {
        text-align: center;
        padding: 0 15px;
        img {
            margin-top:-40px;
            @media (max-width: 767px) {
                margin-top:0px; 
            }
        }
    }
}