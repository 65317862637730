.body_kontakt.option-com_content #bottom {
    background-image:none;
    padding: 20px 0px 50px;
}
#bottom {
    background-image: url('/images/mainbottom-bg.jpg');
    background-position: center;
    background-size: cover;
    padding: 40px 0px;
    * {
        color:white;
    }
    
    .moduletable {
        .module_container {
            background-color: $color-second;
            padding: 25px;
            h5 {
                font-size: 3rem;
            }
            hr {
                margin:0px;
                border-top-color: darken($color-second, 10%);
            }
            p {
                margin-bottom: 10px;
                font-size: 1.4rem;
                font-weight: 300;
                line-height: 1.1;
            }
            ul {
                padding: 0px;
                list-style: none;
                font-size: 1.6rem;
                font-weight: 300;
                line-height: 1.4;
                margin-bottom: 0px;
                margin-top: 5px;
                a {
                    background-color: white;
                    color: $color-main;
                    padding: 10px;
                    margin-top: 10px;
                    display: inline-block;
                    font-size: 1.4rem;
                }
            }
        }
    }
    
    .main {
        .module_container {
            @media (max-width: 767px) {
                margin-top: 30px;
            }
            background-color: $color-main;
        }
        input {
            height: 35px;
            border: 0px;
            padding: 5px 15px;
            width: 100%;
            margin-top: 10px;
            color:black;
        }

        div:nth-child(1) > div > input {
            margin-top: 0px;
        }

        textarea {
            width: 100%;
            padding: 15px;
            resize: none;
            border: 0px;
            margin-bottom: 5px;
            height: 116px;
            color:black;
            @media (max-width: 767px) {
                margin-top: 10px;
            }
        }

        button {
            height: 50px;
            padding: 14px 30px;
            background-color: $color-second;
            border:0px;
            float:right;
        }
    }
}