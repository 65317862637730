/* Transition */
/* $target: all */
/* $time: 200ms */
/* $animation: ease */
@mixin transition($target, $time, $animation) {
    -webkit-transition: $target $time $animation;
    -moz-transition: $target $time $animation;
    -ms-transition: $target $time $animation;
    -o-transition: $target $time $animation;
    transition: $target $time $animation;
}

/* Rem add */
/* $key: font-size */
/* $px: 20px */
$base-font-size: 10px;
$rem-ratio: $base-font-size / 1rem;
@mixin rem($key,$px) {
  #{$key}: $px;
  #{$key}: $px/$rem-ratio;
}

/* Box-shadox */
/* $value: inset 0 100px 100px -100px #000 */
@mixin box-shadow($value) {
    -webkit-box-shadow: $value;
    -moz-box-shadow: $value;
    -ms-box-shadow: $value;
    -o-box-shadow: $value;
    box-shadow: $value;
}