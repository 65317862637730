#maintop-2 {
    .module_container {
        > ul {
            list-style: none;
            padding: 0px 0px 10px;
            margin: 0px;
            border-bottom: 1px solid #f2f2f2;
            li {
                display: inline-block;
                margin-right: 10px;
                text-transform: uppercase;
                line-height: 1.7;
                font-size: 1.1rem;
            }
            .divider {
                margin-left: 10px;
            }
            a {
                color: #c12d82;
            }
            .active {
                color: #535e7e;
            }
        }
    }
}
.llc.breadcrumbs {
    ul {
        list-style: none;
        padding: 0px 0px 10px;
        margin: 0px 0px 30px;
        border-bottom: 1px solid #f2f2f2;
        text-transform: uppercase;
        font-size: 1.1rem;
        font-weight: 500;
        line-height: 1.6rem;
        li {
            display:inline-block;
            a {
                color:$color-main;
            }
            .divider {
                color:#333;
                padding: 0px 5px;
            }
        }
    }
}