#top {
    z-index: 1;
    position: relative;
    border-bottom: 1px solid #f0f0f0;
    @media (max-width: 767px) {
        background-color: #f2f2f2;
    }
    input {
        background-color: #f2f2f2;
        border: 0px;
        width: 100%;
        height: 50px;
        line-height: 40px;
        padding: 0 30px;
        color: $color-main;
        font-size: 1.2rem;
    }
    .toplogin {
        text-align: right;
        .element {
            line-height: 50px;
            display: inline-block;
            width: 50px;
            text-align: center;
            color: #49516b;
            &.large {
                width: auto;
            }
            button.menuitem {
                width: 50px;
                border: 0px;
                background: none;
                padding: 0px;
            }
        }
        .tooltip {
            .tooltip-arrow {
                border-bottom-color: rgb(73, 81, 107);
            }
            .tooltip-inner {
                background-color:rgb(73, 81, 107);
            }
        }
    }
}