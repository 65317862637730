#feature {
    z-index: 1;
    position: relative;
    .wrapper {
        padding: 30px;
        background-color: white;
        margin-top: -75px;
        .left {
            .module_container {
                background-color: #efefef;
                padding: 30px;
                h3 {
                    color:#cb1f81;
                    font-size: 3rem;
                    padding-bottom: 10px;
                }
                h4 {
                    color:#cb1f81;
                    margin-top: 10px;
                }
                p {
                    line-height:1.4;
                }
            }
            .fb {
                .module_container {
                    background-color: white;
                    padding:0px;
                    position: relative;
                    margin-top: 30px;
                    @media (max-width: 767px) {
                        margin-bottom: 30px;
                    }
                    .moduleTitle {
                        line-height: 50px;
                        height: 50px;
                        text-align: center;
                        color: white;
                        @include rem(font-size, 20px);
                        @media (max-width: 1199px) {
                            @include rem(font-size, 16px);
                        }
                        @media (max-width: 991px) {
                            @include rem(font-size, 14px);
                            line-height: 25px;
                        }
                        @media (max-width: 767px) {
                            @include rem(font-size, 12px);
                            line-height: 50px;
                        }
                        padding: 0px 20px 0px 70px;
                        background-color: #3b5998;
                        &:after {
                            content: '\f09a';
                            font-family: 'fontawesome';
                            display: inline-block;
                            width: 50px;
                            height: 50px;
                            line-height: 50px;
                            background-color: #23527c;
                            left:0;
                            top: 0;
                            position: absolute;
                        }
                    }
                }
            }
        } 
        .right {
            .moduletable {
                &:nth-child(1) {
                    .module_container {
                        margin-bottom: 30px;
                    }
                }
                &:nth-child(2) {
                    .module_container {
                        margin-bottom: 30px;
                    }
                }
                @media (max-width: 767px) {
                    &:nth-last-child(1) {
                        .module_container {
                            margin-bottom: 0px;
                        }
                    }
                }
                .module_container {
                    min-height:190px;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    padding: 30px;
                    @media (max-width: 991px) {
                        padding: 20px;
                        min-height:210px;
                        h2 {
                            @include rem(font-size, 24px);
                        }
                    }
                    .module_link_button {
                        display: inline-block;
                        background-color: #cb1f81;
                        padding: 10px;
                        color: white;
                        margin-top: 10px;
                        &:after {
                            content: '\f061';
                            font-family: 'fontawesome';
                            margin-left: 10px;
                        }
                    }
                    @media (max-width: 767px) {
                        margin-bottom: 30px;
                    }
                }
                > a {
                    display: block;
                    width: 100%;
                }
                h2 {
                    color:white;
                    word-break: break-word;
                }
            }
        }
    }
}