#maintop {
    padding: 20px 0px;
    .moduletable {
        .module_container {
            .banner {
                background-color: #49516b;
                padding: 60px 20px 20px;
                background-image: url(/images/h1-bg.png);
                background-position: right top;
                background-repeat: no-repeat;
                h1 {
                    color:white;
                    background-color:#cb1f81;
                    display:inline-block;
                    padding:10px 40px;
                    line-height:1.1;
                    background-image: url(/images/h1-bg.png);
                    background-position: right top;
                    background-repeat: no-repeat;
                    margin-bottom: 0px;
                    font-weight: 300;
                    @media (max-width: 991px) {
                        @include rem(font-size, 30px);
                    }
                    @media (max-width: 767px) {
                        @include rem(font-size, 20px);
                        position: relative;
                    }
                }
            }
            .banner.isset {
                background-position: center;
                background-size: cover;
                padding: 260px 20px 20px;
                @media (max-width: 991px) {
                    padding: 200px 20px 20px;
                }
                @media (max-width: 767px) {
                    padding: 160px 20px 20px;
                }
            }
            
            .llc-banner {
                &.default {
                    .title {
                        background-color: $llc-default-main;
                    }
                }
                &.second {
                    .title {
                        background-color: $llc-second-main;
                    }
                }
                &.third {
                    .title {
                        background-color: $llc-third-main;
                    }
                }
                position: relative;
                overflow: hidden;
                .title {
                    padding: 0px 40px;
                    color: white;
                    bottom:0;
                    margin:20px;
                    @media (max-width: 991px) {
                        @include rem(font-size, 30px);
                    }
                    @media (max-width: 767px) {
                        @include rem(font-size, 20px);
                        position: relative;
                        margin: 0px;
                    }
                    position: absolute;
                    @include rem(font-size, 40px);
                    font-weight:300;
                    line-height:1.4;
                    background-image:url('/images/h1-bg.png');
                    background-repeat:no-repeat;
                    background-position:left 150px top;
                    background-color: $llc-default-main;
                }
                .placeholder {
                    height: 200px;
                    width: 100%;
                    position: relative;
                    background-color: #eeeeee;
                }
            }
        }
    }
}