/* ====== Colors ====== */

/*Dark*/
$color-dark: #252525;

/*Medium*/
$color-medium: lighten($color-dark, 20%);

/*Light*/
$color-light: lighten($color-dark, 40%);

/*Extra Light*/
$color-lightest: #f1f1f1;

/*Main*/
$color-main: #cb1f81;

/*Secondary color*/
$color-second: #49516b;





/* == Default Theme Colors == */

/*Main*/
$llc-default-main: rgb(74, 81, 107);

/*Second color*/
$llc-default-second: rgb(50, 58, 81);

/*Third color*/
$llc-default-third: rgb(140, 146, 160);

/*fourth color*/
$llc-default-fourth: rgb(189, 28, 121);

/* == Second Theme Colors == */

/*Main*/
$llc-second-main: rgb(153, 200, 72);

/*Second color*/
$llc-second-second: rgb(126, 169, 53);

/*Third color*/
$llc-second-third: rgb(140, 146, 160);

/*Fourth color*/
$llc-second-fourth: rgb(74, 81, 107);

/* == Third Theme Colors == */

/*Main*/
$llc-third-main: rgb(23, 0, 178);

/*Second color*/
$llc-third-second: rgb(100, 82, 218);

/*Third color*/
$llc-third-third: rgb(140, 146, 160);

/*Fourth color*/
$llc-third-fourth: rgb(74, 81, 107);







/* == Bootstrap Colors == */

/*Primary*/
$color-primary: #cb1f81;

/*Success*/
$color-success: #5cb85c;

/*Info*/
$color-info: #5bc0de;

/*Warning*/
$color-warning: #f0ad4e;

/*Danger*/
$color-danger: #d9534f;

/* ====== Typography colors ====== */

/*Font Global*/
$font-global: "Roboto",sans-serif;

/*Font Headings*/
$font-headings: "Roboto",sans-serif;

/*Text color*/
$color-text: #151515;

/*Link color*/
$color-link: inherit;

/*Link hover color*/
$color-link-hover: $color-main;

/* ====== Other ====== */

/*Transition time*/
$transition-time: 200ms;

/*Bootstrap spaceing*/
$grid-space: 15px;