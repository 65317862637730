/*======================= !IMPORTS =======================*/
/* Globals */
@import "assets/globals";

/* Mixins */
@import "assets/mixins";

/* Bootstrap */
@import "assets/bootstrap";
@import "assets/bootstrap-theme";
@import "assets/navbar";

/* Font Awesome Icons */
@import "assets/font-awesome.min";

/* Streamline Icons */
@import "assets/streamline";

/* Featherlight */
@import "assets/featherlight";

/* Hover */
@import "assets/hover.min";

/* Flex Grid */
@import "assets/flex";

@import "assets/jquery.mapify";

/*======================= !TYPOGRAPHY / !TEXT / !FONT =======================*/
@import "assets/typography";


/*======================= !COMPONENT OVERRIDE =======================*/

#mobile-menu {
	display: none;
}
img {
  max-width: 100%;
}
html {
    font-size: 62.5%;
    -ms-overflow-style: scrollbar;
}
html,
body {
  height: 100%;
  font-family: $font-global;
}
#wrapper {
    margin: 0 auto;
}
.body_forside #wrapper {
    background: transparent none;
}
#push,
#footer-wrapper {
  position: relative;
}
.clear, .clr {
	clear: both;
	visibility: hidden;
	height: 0;
	margin: 0;
}
.moduletable:after {
  clear: both;
  display: block;
  content: '';
}
[class^="slicon-"], [class*=" slicon-"] {
    display: inline-block;
    vertical-align: bottom;
}
[class^="slicon-"]:before, [class*=" slicon-"]:before {
    vertical-align: middle;
}
a.cover-link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 3;
    text-indent: -9999px;
}
/*======================= !FORMS / !CONTACT =======================*/

/*=================================================================*/
/*======================= !MODULE STYLES ===========================*/
/*=================================================================*/
@import "areas/modules";
/*======================= !MODAL WINDOW =======================*/

/*=================================================================*/
/*======================= !COMPONENT STYLES ========================*/
/*=================================================================*/

/*======================= !EasyBlog & Modules ========================*/

/*======================= !Search & Smart Search ========================*/

/*======================= !ADDTHIS STYLES =======================*/

/*======================= !404 PAGE =======================*/
.errorBox {
  background: none;
  text-align: left;
}
#error .well {
  margin-bottom: 0;
}
/*======================= !SCROLL TO TOP =======================*/
#back-top {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 99;
    -webkit-transform: translateZ(0);
    
    a {
        background-color: $color-main;
        color: white;
        display: block;
        font: 30px/30px "fontawesome";
        height: 42px;
        text-align: center;
        width: 42px;
        border-radius: 50%;
        opacity: 0.3;
        box-shadow: 0px 2px 8px rgba(0,0,0,0.2);
        
        > span:before {
            content: "\f106";
            vertical-align: middle;
        }
        &:hover {
            opacity: 1;
        }
    }
}
body .nav>li>a:hover, body .nav>li>a:focus {
    text-decoration: none;
    background-color: inherit;
}
/*======================= !TOP =======================*/
@import "areas/top";
/*======================= !LOGO =======================*/
@import "areas/logo";
/*======================= !HEADER =======================*/
@import "areas/header";
/*======================= !SHOWCASE =======================*/
@import "areas/showcase";
/*======================= !BANNER =======================*/
@import "areas/banner";
/*======================= !NAVIGATION =======================*/
@import "areas/navigation";
/*======================= !FEATURE =======================*/
@import "areas/feature";
/*======================= !MAINTOP =======================*/
@import "areas/maintop";
/*======================= !BREADCRUMBS =======================*/
@import "areas/breadcrumbs";
/*======================= !CONTENT TOP =======================*/
@import "areas/content-top";
/*======================= !CONTENT =======================*/
@import "areas/content";
/*======================= !CONTENT BOTTOM =======================*/
@import "areas/content-bottom";
/*======================= !ASIDE / !SIDEBAR =======================*/
@import "areas/aside";
/*======================= !MAINBOTTOM =======================*/
@import "areas/mainbottom";
/*======================= !BOTTOM =======================*/
@import "areas/bottom";
/*======================= !FOOTER =======================*/
@import "areas/footer";

#system-message {
    .alert {
        position: fixed;
        top: 10px;
        background: transparent;
        background-color: #cb1f81;
        right: 10px;
        color: white;
        z-index: 100;
        padding: 20px 40px;
        box-shadow: 0 0 20px rgba(73, 81, 107, 0.4);
        text-shadow: 0 0 0 transparent;
        a.close {
            color: white;
            opacity: 1;
            font-weight: 300;
            position: absolute;
            right: 10px;
            top: 5px;
        }
    }
}
body.option-com_llc.view-base.itemid-101 #bottom {
    display: none;
}
@import "assets/responsive";