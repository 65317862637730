@charset "UTF-8";

@font-face {
  font-family: "StreamLine";
  src:url("../fonts/streamline.eot");
  src:url("../fonts/streamline.eot?#iefix") format("embedded-opentype"),
    url("../fonts/streamline.woff") format("woff"),
    url("../fonts/streamline.ttf") format("truetype"),
    url("../fonts/streamline.svg#streamline") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "StreamLine" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="slicon-"]:before,
[class*=" slicon-"]:before {
  font-family: "StreamLine" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slicon-bin-1:before {
  content: "\61";
}
.slicon-bin-2:before {
  content: "\62";
}
.slicon-binocular:before {
  content: "\63";
}
.slicon-bomb:before {
  content: "\64";
}
.slicon-clip-1:before {
  content: "\65";
}
.slicon-clip-2:before {
  content: "\66";
}
.slicon-crosshair-1:before {
  content: "\67";
}
.slicon-crosshair-2:before {
  content: "\68";
}
.slicon-crosshair-3:before {
  content: "\69";
}
.slicon-cutter:before {
  content: "\6a";
}
.slicon-delete-1:before {
  content: "\6b";
}
.slicon-delete-2:before {
  content: "\6c";
}
.slicon-edit-1:before {
  content: "\6d";
}
.slicon-edit-2:before {
  content: "\6e";
}
.slicon-edit-3:before {
  content: "\6f";
}
.slicon-hide:before {
  content: "\70";
}
.slicon-ink:before {
  content: "\71";
}
.slicon-key-1:before {
  content: "\72";
}
.slicon-key-2:before {
  content: "\73";
}
.slicon-link-1:before {
  content: "\74";
}
.slicon-link-2:before {
  content: "\75";
}
.slicon-link-3:before {
  content: "\76";
}
.slicon-link-broken-1:before {
  content: "\77";
}
.slicon-link-broken-2:before {
  content: "\78";
}
.slicon-lock-1:before {
  content: "\79";
}
.slicon-lock-2:before {
  content: "\7a";
}
.slicon-lock-3:before {
  content: "\41";
}
.slicon-lock-4:before {
  content: "\42";
}
.slicon-lock-5:before {
  content: "\43";
}
.slicon-lock-unlock-1:before {
  content: "\44";
}
.slicon-lock-unlock-2:before {
  content: "\45";
}
.slicon-magnifier:before {
  content: "\46";
}
.slicon-pen-1:before {
  content: "\47";
}
.slicon-pen-2:before {
  content: "\48";
}
.slicon-pen-3:before {
  content: "\49";
}
.slicon-pen-4:before {
  content: "\4a";
}
.slicon-pencil-1:before {
  content: "\4b";
}
.slicon-pencil-2:before {
  content: "\4c";
}
.slicon-pencil-3:before {
  content: "\4d";
}
.slicon-pin-1:before {
  content: "\4e";
}
.slicon-pin-2:before {
  content: "\4f";
}
.slicon-power-1:before {
  content: "\50";
}
.slicon-power-2:before {
  content: "\51";
}
.slicon-preview-1:before {
  content: "\52";
}
.slicon-preview-2:before {
  content: "\53";
}
.slicon-scissor-1:before {
  content: "\54";
}
.slicon-scissor-2:before {
  content: "\55";
}
.slicon-skull-1:before {
  content: "\56";
}
.slicon-skull-2:before {
  content: "\57";
}
.slicon-type-1:before {
  content: "\58";
}
.slicon-type-2:before {
  content: "\59";
}
.slicon-type-3:before {
  content: "\5a";
}
.slicon-type-4:before {
  content: "\30";
}
.slicon-zoom-area:before {
  content: "\31";
}
.slicon-zoom-in:before {
  content: "\32";
}
.slicon-zoom-out:before {
  content: "\33";
}
.slicon-cursor-1:before {
  content: "\34";
}
.slicon-cursor-2:before {
  content: "\35";
}
.slicon-cursor-add:before {
  content: "\36";
}
.slicon-cursor-duplicate:before {
  content: "\37";
}
.slicon-cursor-move:before {
  content: "\38";
}
.slicon-cursor-move-2:before {
  content: "\39";
}
.slicon-cursor-select-area:before {
  content: "\21";
}
.slicon-hand:before {
  content: "\22";
}
.slicon-hand-block:before {
  content: "\23";
}
.slicon-hand-grab-1:before {
  content: "\24";
}
.slicon-hand-grab-2:before {
  content: "\25";
}
.slicon-hand-point:before {
  content: "\26";
}
.slicon-hand-touch-1:before {
  content: "\27";
}
.slicon-hand-touch-2:before {
  content: "\28";
}
.slicon-hand-touch-3:before {
  content: "\29";
}
.slicon-hand-touch-4:before {
  content: "\2a";
}
.slicon-bookmark-1:before {
  content: "\2b";
}
.slicon-bookmark-2:before {
  content: "\2c";
}
.slicon-bookmark-3:before {
  content: "\2d";
}
.slicon-bookmark-4:before {
  content: "\2e";
}
.slicon-tag-1:before {
  content: "\2f";
}
.slicon-tag-2:before {
  content: "\3a";
}
.slicon-tag-add:before {
  content: "\3b";
}
.slicon-tag-delete:before {
  content: "\3c";
}
.slicon-tags-1:before {
  content: "\3d";
}
.slicon-tags-2:before {
  content: "\3e";
}
.slicon-anchor-point-1:before {
  content: "\3f";
}
.slicon-anchor-point-2:before {
  content: "\40";
}
.slicon-arrange-1:before {
  content: "\5b";
}
.slicon-arrange-2:before {
  content: "\5d";
}
.slicon-artboard:before {
  content: "\5e";
}
.slicon-brush-1:before {
  content: "\5f";
}
.slicon-brush-2:before {
  content: "\60";
}
.slicon-bucket:before {
  content: "\7b";
}
.slicon-crop:before {
  content: "\7c";
}
.slicon-dropper-1:before {
  content: "\7d";
}
.slicon-dropper-2:before {
  content: "\7e";
}
.slicon-dropper-3:before {
  content: "\5c";
}
.slicon-glue:before {
  content: "\e000";
}
.slicon-grid:before {
  content: "\e001";
}
.slicon-layers:before {
  content: "\e002";
}
.slicon-magic-wand-1:before {
  content: "\e003";
}
.slicon-magic-wand-2:before {
  content: "\e004";
}
.slicon-magnet:before {
  content: "\e005";
}
.slicon-marker:before {
  content: "\e006";
}
.slicon-palette:before {
  content: "\e007";
}
.slicon-pen-5:before {
  content: "\e008";
}
.slicon-pen-6:before {
  content: "\e009";
}
.slicon-quill:before {
  content: "\e00a";
}
.slicon-reflect:before {
  content: "\e00b";
}
.slicon-roller:before {
  content: "\e00c";
}
.slicon-ruler-1:before {
  content: "\e00d";
}
.slicon-ruler-2:before {
  content: "\e00e";
}
.slicon-scale-diagonal-1:before {
  content: "\e00f";
}
.slicon-scale-diagonal-2:before {
  content: "\e010";
}
.slicon-scale-horizontal:before {
  content: "\e011";
}
.slicon-scale-tool-1:before {
  content: "\e012";
}
.slicon-scale-tool-2:before {
  content: "\e013";
}
.slicon-scale-tool-3:before {
  content: "\e014";
}
.slicon-scale-vertical:before {
  content: "\e015";
}
.slicon-shear-tool:before {
  content: "\e016";
}
.slicon-spray:before {
  content: "\e017";
}
.slicon-stamp:before {
  content: "\e018";
}
.slicon-stationery-1:before {
  content: "\e019";
}
.slicon-stationery-2:before {
  content: "\e01a";
}
.slicon-stationery-3:before {
  content: "\e01b";
}
.slicon-vector:before {
  content: "\e01c";
}
.slicon-award-1:before {
  content: "\e01d";
}
.slicon-award-2:before {
  content: "\e01e";
}
.slicon-award-3:before {
  content: "\e01f";
}
.slicon-award-4:before {
  content: "\e020";
}
.slicon-award-5:before {
  content: "\e021";
}
.slicon-award-6:before {
  content: "\e022";
}
.slicon-crown-1:before {
  content: "\e023";
}
.slicon-crown-2:before {
  content: "\e024";
}
.slicon-crown-3:before {
  content: "\e025";
}
.slicon-fire:before {
  content: "\e026";
}
.slicon-flag-1:before {
  content: "\e027";
}
.slicon-flag-2:before {
  content: "\e028";
}
.slicon-flag-3:before {
  content: "\e029";
}
.slicon-flag-4:before {
  content: "\e02a";
}
.slicon-flag-5:before {
  content: "\e02b";
}
.slicon-flag-6:before {
  content: "\e02c";
}
.slicon-flag-7:before {
  content: "\e02d";
}
.slicon-flag-8:before {
  content: "\e02e";
}
.slicon-google-plus-1:before {
  content: "\e02f";
}
.slicon-google-plus-2:before {
  content: "\e030";
}
.slicon-hand-like-1:before {
  content: "\e031";
}
.slicon-hand-like-2:before {
  content: "\e032";
}
.slicon-hand-unlike-1:before {
  content: "\e033";
}
.slicon-hand-unlike-2:before {
  content: "\e034";
}
.slicon-heart-1:before {
  content: "\e035";
}
.slicon-heart-2:before {
  content: "\e036";
}
.slicon-heart-angel:before {
  content: "\e037";
}
.slicon-heart-broken:before {
  content: "\e038";
}
.slicon-heart-minus:before {
  content: "\e039";
}
.slicon-heart-plus:before {
  content: "\e03a";
}
.slicon-present:before {
  content: "\e03b";
}
.slicon-rank-1:before {
  content: "\e03c";
}
.slicon-rank-2:before {
  content: "\e03d";
}
.slicon-ribbon:before {
  content: "\e03e";
}
.slicon-star-1:before {
  content: "\e03f";
}
.slicon-star-2:before {
  content: "\e040";
}
.slicon-star-3:before {
  content: "\e041";
}
.slicon-star-4:before {
  content: "\e042";
}
.slicon-star-5:before {
  content: "\e043";
}
.slicon-star-6:before {
  content: "\e044";
}
.slicon-star-7:before {
  content: "\e045";
}
.slicon-star-8:before {
  content: "\e046";
}
.slicon-star-9:before {
  content: "\e047";
}
.slicon-star-10:before {
  content: "\e048";
}
.slicon-trophy:before {
  content: "\e049";
}
.slicon-bubble-2:before {
  content: "\e04a";
}
.slicon-bubble-add-1:before {
  content: "\e04b";
}
.slicon-bubble-add-2:before {
  content: "\e04c";
}
.slicon-bubble-add-3:before {
  content: "\e04d";
}
.slicon-bubble-ask-1:before {
  content: "\e04e";
}
.slicon-bubble-ask-2:before {
  content: "\e04f";
}
.slicon-bubble-attention-1:before {
  content: "\e050";
}
.slicon-bubble-attention-2:before {
  content: "\e051";
}
.slicon-bubble-attention-3:before {
  content: "\e052";
}
.slicon-bubble-attention-4:before {
  content: "\e053";
}
.slicon-bubble-attention-5:before {
  content: "\e054";
}
.slicon-bubble-attention-6:before {
  content: "\e055";
}
.slicon-bubble-attention-7:before {
  content: "\e056";
}
.slicon-bubble-block-1:before {
  content: "\e057";
}
.slicon-bubble-block-2:before {
  content: "\e058";
}
.slicon-bubble-block-3:before {
  content: "\e059";
}
.slicon-bubble-chat-1:before {
  content: "\e05a";
}
.slicon-bubble-chat-2:before {
  content: "\e05b";
}
.slicon-bubble-check-1:before {
  content: "\e05c";
}
.slicon-bubble-check-2:before {
  content: "\e05d";
}
.slicon-bubble-check-3:before {
  content: "\e05e";
}
.slicon-bubble-comment-1:before {
  content: "\e05f";
}
.slicon-bubble-comment-2:before {
  content: "\e060";
}
.slicon-bubble-conversation-1:before {
  content: "\e061";
}
.slicon-bubble-conversation-2:before {
  content: "\e062";
}
.slicon-bubble-conversation-3:before {
  content: "\e063";
}
.slicon-bubble-conversation-4:before {
  content: "\e064";
}
.slicon-bubble-conversation-5:before {
  content: "\e065";
}
.slicon-bubble-conversation-6:before {
  content: "\e066";
}
.slicon-bubble-delete-1:before {
  content: "\e067";
}
.slicon-bubble-delete-2:before {
  content: "\e068";
}
.slicon-bubble-delete-3:before {
  content: "\e069";
}
.slicon-bubble-edit-1:before {
  content: "\e06a";
}
.slicon-bubble-edit-2:before {
  content: "\e06b";
}
.slicon-bubble-edit-3:before {
  content: "\e06c";
}
.slicon-bubble-heart-1:before {
  content: "\e06d";
}
.slicon-bubble-heart-2:before {
  content: "\e06e";
}
.slicon-bubble-minus-1:before {
  content: "\e06f";
}
.slicon-bubble-minus-2:before {
  content: "\e070";
}
.slicon-bubble-minus-3:before {
  content: "\e071";
}
.slicon-bubble-quote-1:before {
  content: "\e072";
}
.slicon-bubble-quote-2:before {
  content: "\e073";
}
.slicon-bubble-smiley-3:before {
  content: "\e074";
}
.slicon-bubble-smiley-4:before {
  content: "\e075";
}
.slicon-bubble-smiley-smile:before {
  content: "\e076";
}
.slicon-bubble-smiley-wink:before {
  content: "\e077";
}
.slicon-bubble-star-1:before {
  content: "\e078";
}
.slicon-bubble-star-2:before {
  content: "\e079";
}
.slicon-bubble-star-3:before {
  content: "\e07a";
}
.slicon-chat-1:before {
  content: "\e07b";
}
.slicon-chat-2:before {
  content: "\e07c";
}
.slicon-chat-3:before {
  content: "\e07d";
}
.slicon-chat-4:before {
  content: "\e07e";
}
.slicon-chat-5:before {
  content: "\e07f";
}
.slicon-chat-bubble-1:before {
  content: "\e080";
}
.slicon-chat-bubble-2:before {
  content: "\e081";
}
.slicon-smiley-happy-1:before {
  content: "\e082";
}
.slicon-smiley-happy-2:before {
  content: "\e083";
}
.slicon-smiley-happy-3:before {
  content: "\e084";
}
.slicon-smiley-happy-4:before {
  content: "\e085";
}
.slicon-smiley-happy-5:before {
  content: "\e086";
}
.slicon-smiley-relax:before {
  content: "\e087";
}
.slicon-smiley-sad:before {
  content: "\e088";
}
.slicon-smiley-surprise:before {
  content: "\e089";
}
.slicon-thinking-1:before {
  content: "\e08a";
}
.slicon-thinking-2:before {
  content: "\e08b";
}
.slicon-call-1:before {
  content: "\e08c";
}
.slicon-call-2:before {
  content: "\e08d";
}
.slicon-call-3:before {
  content: "\e08e";
}
.slicon-call-4:before {
  content: "\e08f";
}
.slicon-call-add:before {
  content: "\e090";
}
.slicon-call-block:before {
  content: "\e091";
}
.slicon-call-delete:before {
  content: "\e092";
}
.slicon-call-in:before {
  content: "\e093";
}
.slicon-call-minus:before {
  content: "\e094";
}
.slicon-call-out:before {
  content: "\e095";
}
.slicon-contact:before {
  content: "\e096";
}
.slicon-fax:before {
  content: "\e097";
}
.slicon-hang-up:before {
  content: "\e098";
}
.slicon-message:before {
  content: "\e099";
}
.slicon-mobile-phone-1:before {
  content: "\e09a";
}
.slicon-mobile-phone-2:before {
  content: "\e09b";
}
.slicon-phone-1:before {
  content: "\e09c";
}
.slicon-phone-2:before {
  content: "\e09d";
}
.slicon-phone-3:before {
  content: "\e09e";
}
.slicon-phone-4:before {
  content: "\e09f";
}
.slicon-phone-vibration:before {
  content: "\e0a0";
}
.slicon-signal-fine:before {
  content: "\e0a1";
}
.slicon-signal-full:before {
  content: "\e0a2";
}
.slicon-signal-high:before {
  content: "\e0a3";
}
.slicon-signal-no:before {
  content: "\e0a4";
}
.slicon-signal-poor:before {
  content: "\e0a5";
}
.slicon-signal-weak:before {
  content: "\e0a6";
}
.slicon-smartphone:before {
  content: "\e0a7";
}
.slicon-tape:before {
  content: "\e0a8";
}
.slicon-camera-symbol-1:before {
  content: "\e0a9";
}
.slicon-camera-symbol-2:before {
  content: "\e0aa";
}
.slicon-camera-symbol-3:before {
  content: "\e0ab";
}
.slicon-headphone:before {
  content: "\e0ac";
}
.slicon-antenna-1:before {
  content: "\e0ad";
}
.slicon-antenna-2:before {
  content: "\e0ae";
}
.slicon-antenna-3:before {
  content: "\e0af";
}
.slicon-hotspot-1:before {
  content: "\e0b0";
}
.slicon-hotspot-2:before {
  content: "\e0b1";
}
.slicon-link:before {
  content: "\e0b2";
}
.slicon-megaphone-1:before {
  content: "\e0b3";
}
.slicon-megaphone-2:before {
  content: "\e0b4";
}
.slicon-radar:before {
  content: "\e0b5";
}
.slicon-rss-1:before {
  content: "\e0b6";
}
.slicon-rss-2:before {
  content: "\e0b7";
}
.slicon-satellite:before {
  content: "\e0b8";
}
.slicon-address-1:before {
  content: "\e0b9";
}
.slicon-address-2:before {
  content: "\e0ba";
}
.slicon-address-3:before {
  content: "\e0bb";
}
.slicon-forward:before {
  content: "\e0bc";
}
.slicon-inbox-1:before {
  content: "\e0bd";
}
.slicon-inbox-2:before {
  content: "\e0be";
}
.slicon-inbox-3:before {
  content: "\e0bf";
}
.slicon-inbox-4:before {
  content: "\e0c0";
}
.slicon-letter-1:before {
  content: "\e0c1";
}
.slicon-letter-2:before {
  content: "\e0c2";
}
.slicon-letter-3:before {
  content: "\e0c3";
}
.slicon-letter-4:before {
  content: "\e0c4";
}
.slicon-letter-5:before {
  content: "\e0c5";
}
.slicon-mail-1:before {
  content: "\e0c6";
}
.slicon-mail-2:before {
  content: "\e0c7";
}
.slicon-mail-add:before {
  content: "\e0c8";
}
.slicon-mail-attention:before {
  content: "\e0c9";
}
.slicon-mail-block:before {
  content: "\e0ca";
}
.slicon-mail-box-1:before {
  content: "\e0cb";
}
.slicon-mail-box-2:before {
  content: "\e0cc";
}
.slicon-mail-box-3:before {
  content: "\e0cd";
}
.slicon-mail-checked:before {
  content: "\e0ce";
}
.slicon-mail-compose:before {
  content: "\e0cf";
}
.slicon-mail-delete:before {
  content: "\e0d0";
}
.slicon-mail-favorite:before {
  content: "\e0d1";
}
.slicon-mail-inbox:before {
  content: "\e0d2";
}
.slicon-mail-lock:before {
  content: "\e0d3";
}
.slicon-mail-minus:before {
  content: "\e0d4";
}
.slicon-mail-read:before {
  content: "\e0d5";
}
.slicon-mail-recieved-1:before {
  content: "\e0d6";
}
.slicon-mail-recieved-2:before {
  content: "\e0d7";
}
.slicon-mail-search-1:before {
  content: "\e0d8";
}
.slicon-mail-search-2:before {
  content: "\e0d9";
}
.slicon-mail-sent-1:before {
  content: "\e0da";
}
.slicon-mail-sent-2:before {
  content: "\e0db";
}
.slicon-mail-setting:before {
  content: "\e0dc";
}
.slicon-mail-star:before {
  content: "\e0dd";
}
.slicon-mail-sync:before {
  content: "\e0de";
}
.slicon-mail-time:before {
  content: "\e0df";
}
.slicon-outbox-1:before {
  content: "\e0e0";
}
.slicon-outbox-2:before {
  content: "\e0e1";
}
.slicon-plane-paper-1:before {
  content: "\e0e2";
}
.slicon-plane-paper-2:before {
  content: "\e0e3";
}
.slicon-reply-mail-1:before {
  content: "\e0e4";
}
.slicon-reply-mail-2:before {
  content: "\e0e5";
}
.slicon-connection-1:before {
  content: "\e0e6";
}
.slicon-connection-2:before {
  content: "\e0e7";
}
.slicon-connection-3:before {
  content: "\e0e8";
}
.slicon-contacts-1:before {
  content: "\e0e9";
}
.slicon-contacts-2:before {
  content: "\e0ea";
}
.slicon-contacts-3:before {
  content: "\e0eb";
}
.slicon-contacts-4:before {
  content: "\e0ec";
}
.slicon-female:before {
  content: "\e0ed";
}
.slicon-gender-female:before {
  content: "\e0ee";
}
.slicon-gender-male:before {
  content: "\e0ef";
}
.slicon-genders:before {
  content: "\e0f0";
}
.slicon-id-1:before {
  content: "\e0f1";
}
.slicon-id-2:before {
  content: "\e0f2";
}
.slicon-id-3:before {
  content: "\e0f3";
}
.slicon-id-4:before {
  content: "\e0f4";
}
.slicon-id-5:before {
  content: "\e0f5";
}
.slicon-id-6:before {
  content: "\e0f6";
}
.slicon-id-7:before {
  content: "\e0f7";
}
.slicon-id-8:before {
  content: "\e0f8";
}
.slicon-male:before {
  content: "\e0f9";
}
.slicon-profile-1:before {
  content: "\e0fa";
}
.slicon-profile-2:before {
  content: "\e0fb";
}
.slicon-profile-3:before {
  content: "\e0fc";
}
.slicon-profile-4:before {
  content: "\e0fd";
}
.slicon-profile-5:before {
  content: "\e0fe";
}
.slicon-profile-6:before {
  content: "\e0ff";
}
.slicon-profile-athlete:before {
  content: "\e100";
}
.slicon-profile-bussiness-man:before {
  content: "\e101";
}
.slicon-profile-chef:before {
  content: "\e102";
}
.slicon-profile-cop:before {
  content: "\e103";
}
.slicon-profile-doctor-1:before {
  content: "\e104";
}
.slicon-profile-doctor-2:before {
  content: "\e105";
}
.slicon-profile-gentleman-1:before {
  content: "\e106";
}
.slicon-profile-gentleman-2:before {
  content: "\e107";
}
.slicon-profile-graduate:before {
  content: "\e108";
}
.slicon-profile-king:before {
  content: "\e109";
}
.slicon-profile-lady-1:before {
  content: "\e10a";
}
.slicon-profile-lady-2:before {
  content: "\e10b";
}
.slicon-profile-man:before {
  content: "\e10c";
}
.slicon-profile-nurse1:before {
  content: "\e10d";
}
.slicon-profile-nurse-2:before {
  content: "\e10e";
}
.slicon-profile-prisoner:before {
  content: "\e10f";
}
.slicon-profile-serviceman-1:before {
  content: "\e110";
}
.slicon-profile-serviceman-2:before {
  content: "\e111";
}
.slicon-profile-spy:before {
  content: "\e112";
}
.slicon-profile-teacher:before {
  content: "\e113";
}
.slicon-profile-thief:before {
  content: "\e114";
}
.slicon-user-1:before {
  content: "\e115";
}
.slicon-user-2:before {
  content: "\e116";
}
.slicon-user-add-1:before {
  content: "\e117";
}
.slicon-user-add-2:before {
  content: "\e118";
}
.slicon-user-block-1:before {
  content: "\e119";
}
.slicon-user-block-2:before {
  content: "\e11a";
}
.slicon-user-checked-1:before {
  content: "\e11b";
}
.slicon-user-checked-2:before {
  content: "\e11c";
}
.slicon-user-delete-1:before {
  content: "\e11d";
}
.slicon-user-delete-2:before {
  content: "\e11e";
}
.slicon-user-edit-1:before {
  content: "\e11f";
}
.slicon-user-edit-2:before {
  content: "\e120";
}
.slicon-user-heart-1:before {
  content: "\e121";
}
.slicon-user-heart-2:before {
  content: "\e122";
}
.slicon-user-lock-1:before {
  content: "\e123";
}
.slicon-user-lock-2:before {
  content: "\e124";
}
.slicon-user-minus-1:before {
  content: "\e125";
}
.slicon-user-minus-2:before {
  content: "\e126";
}
.slicon-user-search-1:before {
  content: "\e127";
}
.slicon-user-search-2:before {
  content: "\e128";
}
.slicon-user-setting-1:before {
  content: "\e129";
}
.slicon-user-setting-2:before {
  content: "\e12a";
}
.slicon-user-star-1:before {
  content: "\e12b";
}
.slicon-user-star-2:before {
  content: "\e12c";
}
.slicon-bag-shopping-1:before {
  content: "\e12d";
}
.slicon-bag-shopping-2:before {
  content: "\e12e";
}
.slicon-bag-shopping-3:before {
  content: "\e12f";
}
.slicon-basket-1:before {
  content: "\e130";
}
.slicon-basket-2:before {
  content: "\e131";
}
.slicon-basket-3:before {
  content: "\e132";
}
.slicon-basket-add:before {
  content: "\e133";
}
.slicon-basket-minus:before {
  content: "\e134";
}
.slicon-briefcase:before {
  content: "\e135";
}
.slicon-cart-1:before {
  content: "\e136";
}
.slicon-cart-2:before {
  content: "\e137";
}
.slicon-cart-3:before {
  content: "\e138";
}
.slicon-cart-4:before {
  content: "\e139";
}
.slicon-cut:before {
  content: "\e13a";
}
.slicon-handbag-1:before {
  content: "\e13b";
}
.slicon-handbag-2:before {
  content: "\e13c";
}
.slicon-purse-1:before {
  content: "\e13d";
}
.slicon-purse-2:before {
  content: "\e13e";
}
.slicon-qr-code:before {
  content: "\e13f";
}
.slicon-receipt-1:before {
  content: "\e140";
}
.slicon-receipt-2:before {
  content: "\e141";
}
.slicon-receipt-3:before {
  content: "\e142";
}
.slicon-receipt-4:before {
  content: "\e143";
}
.slicon-shopping-1:before {
  content: "\e144";
}
.slicon-sign-new-1:before {
  content: "\e145";
}
.slicon-sign-new-2:before {
  content: "\e146";
}
.slicon-sign-parking:before {
  content: "\e147";
}
.slicon-signal-star:before {
  content: "\e148";
}
.slicon-trolley-1:before {
  content: "\e149";
}
.slicon-trolley-2:before {
  content: "\e14a";
}
.slicon-trolley-3:before {
  content: "\e14b";
}
.slicon-trolley-load:before {
  content: "\e14c";
}
.slicon-trolley-off:before {
  content: "\e14d";
}
.slicon-wallet-1:before {
  content: "\e14e";
}
.slicon-wallet-2:before {
  content: "\e14f";
}
.slicon-wallet-3:before {
  content: "\e150";
}
.slicon-camera-1:before {
  content: "\e151";
}
.slicon-camera-2:before {
  content: "\e152";
}
.slicon-camera-3:before {
  content: "\e153";
}
.slicon-camera-4:before {
  content: "\e154";
}
.slicon-camera-5:before {
  content: "\e155";
}
.slicon-camera-back:before {
  content: "\e156";
}
.slicon-camera-focus:before {
  content: "\e157";
}
.slicon-camera-frames:before {
  content: "\e158";
}
.slicon-camera-front:before {
  content: "\e159";
}
.slicon-camera-graph-1:before {
  content: "\e15a";
}
.slicon-camera-graph-2:before {
  content: "\e15b";
}
.slicon-camera-landscape:before {
  content: "\e15c";
}
.slicon-camera-lens-1:before {
  content: "\e15d";
}
.slicon-camera-lens-2:before {
  content: "\e15e";
}
.slicon-camera-light:before {
  content: "\e15f";
}
.slicon-camera-portrait:before {
  content: "\e160";
}
.slicon-camera-view:before {
  content: "\e161";
}
.slicon-film-1:before {
  content: "\e162";
}
.slicon-film-2:before {
  content: "\e163";
}
.slicon-photo-1:before {
  content: "\e164";
}
.slicon-photo-2:before {
  content: "\e165";
}
.slicon-photo-frame:before {
  content: "\e166";
}
.slicon-photos-1:before {
  content: "\e167";
}
.slicon-photos-2:before {
  content: "\e168";
}
.slicon-polaroid:before {
  content: "\e169";
}
.slicon-signal-camera-1:before {
  content: "\e16a";
}
.slicon-signal-camera-2:before {
  content: "\e16b";
}
.slicon-user-photo:before {
  content: "\e16c";
}
.slicon-backward-1:before {
  content: "\e16d";
}
.slicon-dvd-player:before {
  content: "\e16e";
}
.slicon-eject-1:before {
  content: "\e16f";
}
.slicon-film-3:before {
  content: "\e170";
}
.slicon-forward-1:before {
  content: "\e171";
}
.slicon-handycam:before {
  content: "\e172";
}
.slicon-movie-play-1:before {
  content: "\e173";
}
.slicon-movie-play-2:before {
  content: "\e174";
}
.slicon-movie-play-3:before {
  content: "\e175";
}
.slicon-next-1:before {
  content: "\e176";
}
.slicon-pause-1:before {
  content: "\e177";
}
.slicon-play-1:before {
  content: "\e178";
}
.slicon-player:before {
  content: "\e179";
}
.slicon-previous-1:before {
  content: "\e17a";
}
.slicon-record-1:before {
  content: "\e17b";
}
.slicon-slate:before {
  content: "\e17c";
}
.slicon-stop-1:before {
  content: "\e17d";
}
.slicon-television:before {
  content: "\e17e";
}
.slicon-video-camera-1:before {
  content: "\e17f";
}
.slicon-video-camera-2:before {
  content: "\e180";
}
.slicon-backward-2:before {
  content: "\e181";
}
.slicon-cd:before {
  content: "\e182";
}
.slicon-eject-2:before {
  content: "\e183";
}
.slicon-equalizer-2:before {
  content: "\e184";
}
.slicon-equalizer-3:before {
  content: "\e185";
}
.slicon-forward-2:before {
  content: "\e186";
}
.slicon-gramophone:before {
  content: "\e187";
}
.slicon-gramophone-record-2:before {
  content: "\e188";
}
.slicon-guitar:before {
  content: "\e189";
}
.slicon-headphone-1:before {
  content: "\e18a";
}
.slicon-headphone-2:before {
  content: "\e18b";
}
.slicon-microphone-1:before {
  content: "\e18c";
}
.slicon-microphone-2:before {
  content: "\e18d";
}
.slicon-microphone-3:before {
  content: "\e18e";
}
.slicon-movie-play-4:before {
  content: "\e18f";
}
.slicon-music-note-1:before {
  content: "\e190";
}
.slicon-music-note-2:before {
  content: "\e191";
}
.slicon-music-note-3:before {
  content: "\e192";
}
.slicon-music-note-4:before {
  content: "\e193";
}
.slicon-next-2:before {
  content: "\e194";
}
.slicon-notes-1:before {
  content: "\e195";
}
.slicon-notes-2:before {
  content: "\e196";
}
.slicon-pause-2:before {
  content: "\e197";
}
.slicon-piano:before {
  content: "\e198";
}
.slicon-play-2:before {
  content: "\e199";
}
.slicon-playlist:before {
  content: "\e19a";
}
.slicon-previous-2:before {
  content: "\e19b";
}
.slicon-radio-1:before {
  content: "\e19c";
}
.slicon-radio-2:before {
  content: "\e19d";
}
.slicon-record-2:before {
  content: "\e19e";
}
.slicon-recorder:before {
  content: "\e19f";
}
.slicon-saxophone:before {
  content: "\e1a0";
}
.slicon-speaker-1:before {
  content: "\e1a1";
}
.slicon-speaker-2:before {
  content: "\e1a2";
}
.slicon-speaker-3:before {
  content: "\e1a3";
}
.slicon-stop-2:before {
  content: "\e1a4";
}
.slicon-tape-1:before {
  content: "\e1a5";
}
.slicon-trumpet:before {
  content: "\e1a6";
}
.slicon-volume-down-1:before {
  content: "\e1a7";
}
.slicon-volume-down-2:before {
  content: "\e1a8";
}
.slicon-volume-loud-1:before {
  content: "\e1a9";
}
.slicon-volume-loud-2:before {
  content: "\e1aa";
}
.slicon-volume-low-1:before {
  content: "\e1ab";
}
.slicon-volume-low-2:before {
  content: "\e1ac";
}
.slicon-volume-medium-1:before {
  content: "\e1ad";
}
.slicon-volume-medium-2:before {
  content: "\e1ae";
}
.slicon-volume-mute-1:before {
  content: "\e1af";
}
.slicon-volume-mute-2:before {
  content: "\e1b0";
}
.slicon-volume-mute-3:before {
  content: "\e1b1";
}
.slicon-volume-up-1:before {
  content: "\e1b2";
}
.slicon-volume-up-2:before {
  content: "\e1b3";
}
.slicon-walkman:before {
  content: "\e1b4";
}
.slicon-cloud:before {
  content: "\e1b5";
}
.slicon-cloud-add:before {
  content: "\e1b6";
}
.slicon-cloud-checked:before {
  content: "\e1b7";
}
.slicon-cloud-delete:before {
  content: "\e1b8";
}
.slicon-cloud-download:before {
  content: "\e1b9";
}
.slicon-cloud-minus:before {
  content: "\e1ba";
}
.slicon-cloud-refresh:before {
  content: "\e1bb";
}
.slicon-cloud-sync:before {
  content: "\e1bc";
}
.slicon-cloud-upload:before {
  content: "\e1bd";
}
.slicon-download-1:before {
  content: "\e1be";
}
.slicon-download-2:before {
  content: "\e1bf";
}
.slicon-download-3:before {
  content: "\e1c0";
}
.slicon-download-4:before {
  content: "\e1c1";
}
.slicon-download-5:before {
  content: "\e1c2";
}
.slicon-download-6:before {
  content: "\e1c3";
}
.slicon-download-7:before {
  content: "\e1c4";
}
.slicon-download-8:before {
  content: "\e1c5";
}
.slicon-download-9:before {
  content: "\e1c6";
}
.slicon-download-10:before {
  content: "\e1c7";
}
.slicon-download-11:before {
  content: "\e1c8";
}
.slicon-download-12:before {
  content: "\e1c9";
}
.slicon-download-13:before {
  content: "\e1ca";
}
.slicon-download-14:before {
  content: "\e1cb";
}
.slicon-download-15:before {
  content: "\e1cc";
}
.slicon-download-file:before {
  content: "\e1cd";
}
.slicon-download-folder:before {
  content: "\e1ce";
}
.slicon-goal-1:before {
  content: "\e1cf";
}
.slicon-goal-2:before {
  content: "\e1d0";
}
.slicon-transfer-1:before {
  content: "\e1d1";
}
.slicon-transfer-2:before {
  content: "\e1d2";
}
.slicon-transfer-3:before {
  content: "\e1d3";
}
.slicon-transfer-4:before {
  content: "\e1d4";
}
.slicon-transfer-5:before {
  content: "\e1d5";
}
.slicon-transfer-6:before {
  content: "\e1d6";
}
.slicon-transfer-7:before {
  content: "\e1d7";
}
.slicon-transfer-8:before {
  content: "\e1d8";
}
.slicon-transfer-9:before {
  content: "\e1d9";
}
.slicon-transfer-10:before {
  content: "\e1da";
}
.slicon-transfer-11:before {
  content: "\e1db";
}
.slicon-transfer-12:before {
  content: "\e1dc";
}
.slicon-upload-1:before {
  content: "\e1dd";
}
.slicon-upload-2:before {
  content: "\e1de";
}
.slicon-upload-3:before {
  content: "\e1df";
}
.slicon-upload-4:before {
  content: "\e1e0";
}
.slicon-upload-5:before {
  content: "\e1e1";
}
.slicon-upload-6:before {
  content: "\e1e2";
}
.slicon-upload-7:before {
  content: "\e1e3";
}
.slicon-upload-8:before {
  content: "\e1e4";
}
.slicon-upload-9:before {
  content: "\e1e5";
}
.slicon-upload-10:before {
  content: "\e1e6";
}
.slicon-upload-11:before {
  content: "\e1e7";
}
.slicon-upload-12:before {
  content: "\e1e8";
}
.slicon-clipboard-1:before {
  content: "\e1e9";
}
.slicon-clipboard-2:before {
  content: "\e1ea";
}
.slicon-clipboard-3:before {
  content: "\e1eb";
}
.slicon-clipboard-add:before {
  content: "\e1ec";
}
.slicon-clipboard-block:before {
  content: "\e1ed";
}
.slicon-clipboard-checked:before {
  content: "\e1ee";
}
.slicon-clipboard-delete:before {
  content: "\e1ef";
}
.slicon-clipboard-edit:before {
  content: "\e1f0";
}
.slicon-clipboard-minus:before {
  content: "\e1f1";
}
.slicon-document-1:before {
  content: "\e1f2";
}
.slicon-document-2:before {
  content: "\e1f3";
}
.slicon-file-1:before {
  content: "\e1f4";
}
.slicon-file-2:before {
  content: "\e1f5";
}
.slicon-file-add:before {
  content: "\e1f6";
}
.slicon-file-attention:before {
  content: "\e1f7";
}
.slicon-file-block:before {
  content: "\e1f8";
}
.slicon-file-bookmark:before {
  content: "\e1f9";
}
.slicon-file-checked:before {
  content: "\e1fa";
}
.slicon-file-code:before {
  content: "\e1fb";
}
.slicon-file-delete:before {
  content: "\e1fc";
}
.slicon-file-download:before {
  content: "\e1fd";
}
.slicon-file-edit:before {
  content: "\e1fe";
}
.slicon-file-favorite-1:before {
  content: "\e1ff";
}
.slicon-file-favorite-2:before {
  content: "\e200";
}
.slicon-file-graph-1:before {
  content: "\e201";
}
.slicon-file-graph-2:before {
  content: "\e202";
}
.slicon-file-home:before {
  content: "\e203";
}
.slicon-file-image-1:before {
  content: "\e204";
}
.slicon-file-image-2:before {
  content: "\e205";
}
.slicon-file-list:before {
  content: "\e206";
}
.slicon-file-lock:before {
  content: "\e207";
}
.slicon-file-media:before {
  content: "\e208";
}
.slicon-file-minus:before {
  content: "\e209";
}
.slicon-file-music:before {
  content: "\e20a";
}
.slicon-file-new:before {
  content: "\e20b";
}
.slicon-file-registry:before {
  content: "\e20c";
}
.slicon-file-search:before {
  content: "\e20d";
}
.slicon-file-setting:before {
  content: "\e20e";
}
.slicon-file-sync:before {
  content: "\e20f";
}
.slicon-file-table:before {
  content: "\e210";
}
.slicon-file-thumbnail:before {
  content: "\e211";
}
.slicon-file-time:before {
  content: "\e212";
}
.slicon-file-transfer:before {
  content: "\e213";
}
.slicon-file-upload:before {
  content: "\e214";
}
.slicon-file-zip:before {
  content: "\e215";
}
.slicon-files-1:before {
  content: "\e216";
}
.slicon-files-2:before {
  content: "\e217";
}
.slicon-files-3:before {
  content: "\e218";
}
.slicon-files-4:before {
  content: "\e219";
}
.slicon-files-5:before {
  content: "\e21a";
}
.slicon-files-6:before {
  content: "\e21b";
}
.slicon-hand-file-1:before {
  content: "\e21c";
}
.slicon-hand-file-2:before {
  content: "\e21d";
}
.slicon-note-paper-1:before {
  content: "\e21e";
}
.slicon-note-paper-2:before {
  content: "\e21f";
}
.slicon-note-paper-add:before {
  content: "\e220";
}
.slicon-note-paper-attention:before {
  content: "\e221";
}
.slicon-note-paper-block:before {
  content: "\e222";
}
.slicon-note-paper-checked:before {
  content: "\e223";
}
.slicon-note-paper-delete:before {
  content: "\e224";
}
.slicon-note-paper-download:before {
  content: "\e225";
}
.slicon-note-paper-edit:before {
  content: "\e226";
}
.slicon-note-paper-favorite:before {
  content: "\e227";
}
.slicon-note-paper-lock:before {
  content: "\e228";
}
.slicon-note-paper-minus:before {
  content: "\e229";
}
.slicon-note-paper-search:before {
  content: "\e22a";
}
.slicon-note-paper-sync:before {
  content: "\e22b";
}
.slicon-note-paper-upload:before {
  content: "\e22c";
}
.slicon-print:before {
  content: "\e22d";
}
.slicon-folder-1:before {
  content: "\e22e";
}
.slicon-folder-2:before {
  content: "\e22f";
}
.slicon-folder-3:before {
  content: "\e230";
}
.slicon-folder-4:before {
  content: "\e231";
}
.slicon-folder-add:before {
  content: "\e232";
}
.slicon-folder-attention:before {
  content: "\e233";
}
.slicon-folder-block:before {
  content: "\e234";
}
.slicon-folder-bookmark:before {
  content: "\e235";
}
.slicon-folder-checked:before {
  content: "\e236";
}
.slicon-folder-code:before {
  content: "\e237";
}
.slicon-folder-delete:before {
  content: "\e238";
}
.slicon-folder-download:before {
  content: "\e239";
}
.slicon-folder-edit:before {
  content: "\e23a";
}
.slicon-folder-favorite:before {
  content: "\e23b";
}
.slicon-folder-home:before {
  content: "\e23c";
}
.slicon-folder-image:before {
  content: "\e23d";
}
.slicon-folder-lock:before {
  content: "\e23e";
}
.slicon-folder-media:before {
  content: "\e23f";
}
.slicon-folder-minus:before {
  content: "\e240";
}
.slicon-folder-music:before {
  content: "\e241";
}
.slicon-folder-new:before {
  content: "\e242";
}
.slicon-folder-search:before {
  content: "\e243";
}
.slicon-folder-setting:before {
  content: "\e244";
}
.slicon-folder-share-1:before {
  content: "\e245";
}
.slicon-folder-share-2:before {
  content: "\e246";
}
.slicon-folder-sync:before {
  content: "\e247";
}
.slicon-folder-transfer:before {
  content: "\e248";
}
.slicon-folder-upload:before {
  content: "\e249";
}
.slicon-folder-zip:before {
  content: "\e24a";
}
.slicon-add-1:before {
  content: "\e24b";
}
.slicon-add-2:before {
  content: "\e24c";
}
.slicon-add-3:before {
  content: "\e24d";
}
.slicon-add-4:before {
  content: "\e24e";
}
.slicon-add-tag:before {
  content: "\e24f";
}
.slicon-arrow-1:before {
  content: "\e250";
}
.slicon-arrow-2:before {
  content: "\e251";
}
.slicon-arrow-down-1:before {
  content: "\e252";
}
.slicon-arrow-down-2:before {
  content: "\e253";
}
.slicon-arrow-left-1:before {
  content: "\e254";
}
.slicon-arrow-left-2:before {
  content: "\e255";
}
.slicon-arrow-move-1:before {
  content: "\e256";
}
.slicon-arrow-move-down:before {
  content: "\e257";
}
.slicon-arrow-move-left:before {
  content: "\e258";
}
.slicon-arrow-move-right:before {
  content: "\e259";
}
.slicon-arrow-move-up:before {
  content: "\e25a";
}
.slicon-arrow-right-1:before {
  content: "\e25b";
}
.slicon-arrow-right-2:before {
  content: "\e25c";
}
.slicon-arrow-up-1:before {
  content: "\e25d";
}
.slicon-arrow-up-2:before {
  content: "\e25e";
}
.slicon-back:before {
  content: "\e25f";
}
.slicon-center-expand:before {
  content: "\e260";
}
.slicon-center-reduce:before {
  content: "\e261";
}
.slicon-delete-1-1:before {
  content: "\e262";
}
.slicon-delete-2-1:before {
  content: "\e263";
}
.slicon-delete-3:before {
  content: "\e264";
}
.slicon-delete-4:before {
  content: "\e265";
}
.slicon-delete-tag:before {
  content: "\e266";
}
.slicon-expand-horizontal:before {
  content: "\e267";
}
.slicon-expand-vertical:before {
  content: "\e268";
}
.slicon-forward-3:before {
  content: "\e269";
}
.slicon-infinity:before {
  content: "\e26a";
}
.slicon-loading:before {
  content: "\e26b";
}
.slicon-log-out-1:before {
  content: "\e26c";
}
.slicon-log-out-2:before {
  content: "\e26d";
}
.slicon-loop-1:before {
  content: "\e26e";
}
.slicon-loop-2:before {
  content: "\e26f";
}
.slicon-loop-3:before {
  content: "\e270";
}
.slicon-minus-1:before {
  content: "\e271";
}
.slicon-minus-2:before {
  content: "\e272";
}
.slicon-minus-3:before {
  content: "\e273";
}
.slicon-minus-4:before {
  content: "\e274";
}
.slicon-minus-tag:before {
  content: "\e275";
}
.slicon-move-diagonal-1:before {
  content: "\e276";
}
.slicon-move-diagonal-2:before {
  content: "\e277";
}
.slicon-move-horizontal-1:before {
  content: "\e278";
}
.slicon-move-horizontal-2:before {
  content: "\e279";
}
.slicon-move-vertical-1:before {
  content: "\e27a";
}
.slicon-move-vertical-2:before {
  content: "\e27b";
}
.slicon-next-1-1:before {
  content: "\e27c";
}
.slicon-next-2-1:before {
  content: "\e27d";
}
.slicon-power-1-1:before {
  content: "\e27e";
}
.slicon-power-2-1:before {
  content: "\e27f";
}
.slicon-power-3:before {
  content: "\e280";
}
.slicon-power-4:before {
  content: "\e281";
}
.slicon-recycle:before {
  content: "\e282";
}
.slicon-refresh:before {
  content: "\e283";
}
.slicon-repeat:before {
  content: "\e284";
}
.slicon-return:before {
  content: "\e285";
}
.slicon-scale-all-1:before {
  content: "\e286";
}
.slicon-scale-center:before {
  content: "\e287";
}
.slicon-scale-horizontal-1:before {
  content: "\e288";
}
.slicon-scale-horizontal-2:before {
  content: "\e289";
}
.slicon-scale-reduce-1:before {
  content: "\e28a";
}
.slicon-scale-reduce-2:before {
  content: "\e28b";
}
.slicon-scale-reduce-3:before {
  content: "\e28c";
}
.slicon-scale-spread-1:before {
  content: "\e28d";
}
.slicon-scale-spread-2:before {
  content: "\e28e";
}
.slicon-scale-spread-3:before {
  content: "\e28f";
}
.slicon-scale-vertical-1:before {
  content: "\e290";
}
.slicon-scale-vertical-2:before {
  content: "\e291";
}
.slicon-scroll-horizontal-1:before {
  content: "\e292";
}
.slicon-scroll-horizontal-2:before {
  content: "\e293";
}
.slicon-scroll-omnidirectional-1:before {
  content: "\e294";
}
.slicon-scroll-omnidirectional-2:before {
  content: "\e295";
}
.slicon-scroll-vertical-1:before {
  content: "\e296";
}
.slicon-scroll-vertical-2:before {
  content: "\e297";
}
.slicon-shuffle:before {
  content: "\e298";
}
.slicon-split:before {
  content: "\e299";
}
.slicon-sync-1:before {
  content: "\e29a";
}
.slicon-sync-2:before {
  content: "\e29b";
}
.slicon-timer:before {
  content: "\e29c";
}
.slicon-transfer:before {
  content: "\e29d";
}
.slicon-transfer-1-1:before {
  content: "\e29e";
}
.slicon-check-1:before {
  content: "\e29f";
}
.slicon-check-2:before {
  content: "\e2a0";
}
.slicon-check-3:before {
  content: "\e2a1";
}
.slicon-check-box:before {
  content: "\e2a2";
}
.slicon-check-bubble:before {
  content: "\e2a3";
}
.slicon-check-circle-1:before {
  content: "\e2a4";
}
.slicon-check-circle-2:before {
  content: "\e2a5";
}
.slicon-check-list:before {
  content: "\e2a6";
}
.slicon-check-shield:before {
  content: "\e2a7";
}
.slicon-cross:before {
  content: "\e2a8";
}
.slicon-cross-bubble:before {
  content: "\e2a9";
}
.slicon-cross-shield:before {
  content: "\e2aa";
}
.slicon-briefcase-1:before {
  content: "\e2ab";
}
.slicon-brightness-high:before {
  content: "\e2ac";
}
.slicon-brightness-low:before {
  content: "\e2ad";
}
.slicon-hammer-1:before {
  content: "\e2ae";
}
.slicon-hammer-2:before {
  content: "\e2af";
}
.slicon-pulse:before {
  content: "\e2b0";
}
.slicon-scale:before {
  content: "\e2b1";
}
.slicon-screw-driver:before {
  content: "\e2b2";
}
.slicon-setting-adjustment:before {
  content: "\e2b3";
}
.slicon-setting-gear:before {
  content: "\e2b4";
}
.slicon-setting-gears-1:before {
  content: "\e2b5";
}
.slicon-setting-gears-2:before {
  content: "\e2b6";
}
.slicon-setting-wrenches:before {
  content: "\e2b7";
}
.slicon-switch-1:before {
  content: "\e2b8";
}
.slicon-switch-2:before {
  content: "\e2b9";
}
.slicon-wrench:before {
  content: "\e2ba";
}
.slicon-alarm-1:before {
  content: "\e2bb";
}
.slicon-alarm-clock:before {
  content: "\e2bc";
}
.slicon-alarm-off:before {
  content: "\e2bd";
}
.slicon-alarm-snooze:before {
  content: "\e2be";
}
.slicon-bell:before {
  content: "\e2bf";
}
.slicon-calendar-1:before {
  content: "\e2c0";
}
.slicon-calendar-2:before {
  content: "\e2c1";
}
.slicon-clock-1:before {
  content: "\e2c2";
}
.slicon-clock-2:before {
  content: "\e2c3";
}
.slicon-clock-3:before {
  content: "\e2c4";
}
.slicon-hourglass-1:before {
  content: "\e2c5";
}
.slicon-hourglass-2:before {
  content: "\e2c6";
}
.slicon-timer-1:before {
  content: "\e2c7";
}
.slicon-timer-3-quarter-1:before {
  content: "\e2c8";
}
.slicon-timer-3-quarter-2:before {
  content: "\e2c9";
}
.slicon-timer-full-1:before {
  content: "\e2ca";
}
.slicon-timer-full-2:before {
  content: "\e2cb";
}
.slicon-timer-half-1:before {
  content: "\e2cc";
}
.slicon-timer-half-2:before {
  content: "\e2cd";
}
.slicon-timer-half-3:before {
  content: "\e2ce";
}
.slicon-timer-half-4:before {
  content: "\e2cf";
}
.slicon-timer-quarter-1:before {
  content: "\e2d0";
}
.slicon-timer-quarter-2:before {
  content: "\e2d1";
}
.slicon-watch-1:before {
  content: "\e2d2";
}
.slicon-watch-2:before {
  content: "\e2d3";
}
.slicon-alert-1:before {
  content: "\e2d4";
}
.slicon-alert-2:before {
  content: "\e2d5";
}
.slicon-alert-3:before {
  content: "\e2d6";
}
.slicon-information:before {
  content: "\e2d7";
}
.slicon-nuclear-1:before {
  content: "\e2d8";
}
.slicon-nuclear-2:before {
  content: "\e2d9";
}
.slicon-question-mark:before {
  content: "\e2da";
}
.slicon-abacus:before {
  content: "\e2db";
}
.slicon-amex-card:before {
  content: "\e2dc";
}
.slicon-atm:before {
  content: "\e2dd";
}
.slicon-balance:before {
  content: "\e2de";
}
.slicon-bank-1:before {
  content: "\e2df";
}
.slicon-bank-2:before {
  content: "\e2e0";
}
.slicon-bank-note-1:before {
  content: "\e2e1";
}
.slicon-bank-note-2:before {
  content: "\e2e2";
}
.slicon-bank-note-3:before {
  content: "\e2e3";
}
.slicon-bitcoins:before {
  content: "\e2e4";
}
.slicon-board:before {
  content: "\e2e5";
}
.slicon-box-1:before {
  content: "\e2e6";
}
.slicon-box-2:before {
  content: "\e2e7";
}
.slicon-box-3:before {
  content: "\e2e8";
}
.slicon-box-download:before {
  content: "\e2e9";
}
.slicon-box-shipping:before {
  content: "\e2ea";
}
.slicon-box-upload:before {
  content: "\e2eb";
}
.slicon-business-chart-1:before {
  content: "\e2ec";
}
.slicon-business-chart-2:before {
  content: "\e2ed";
}
.slicon-calculator-1:before {
  content: "\e2ee";
}
.slicon-calculator-2:before {
  content: "\e2ef";
}
.slicon-calculator-3:before {
  content: "\e2f0";
}
.slicon-cash-register:before {
  content: "\e2f1";
}
.slicon-chart-board:before {
  content: "\e2f2";
}
.slicon-chart-down:before {
  content: "\e2f3";
}
.slicon-chart-up:before {
  content: "\e2f4";
}
.slicon-check:before {
  content: "\e2f5";
}
.slicon-coins-1:before {
  content: "\e2f6";
}
.slicon-coins-2:before {
  content: "\e2f7";
}
.slicon-court:before {
  content: "\e2f8";
}
.slicon-credit-card:before {
  content: "\e2f9";
}
.slicon-credit-card-lock:before {
  content: "\e2fa";
}
.slicon-delivery:before {
  content: "\e2fb";
}
.slicon-dollar-bag:before {
  content: "\e2fc";
}
.slicon-dollar-currency-1:before {
  content: "\e2fd";
}
.slicon-dollar-currency-2:before {
  content: "\e2fe";
}
.slicon-dollar-currency-3:before {
  content: "\e2ff";
}
.slicon-dollar-currency-4:before {
  content: "\e300";
}
.slicon-euro-bag:before {
  content: "\e301";
}
.slicon-euro-currency-1:before {
  content: "\e302";
}
.slicon-euro-currency-2:before {
  content: "\e303";
}
.slicon-euro-currency-3:before {
  content: "\e304";
}
.slicon-euro-currency-4:before {
  content: "\e305";
}
.slicon-forklift:before {
  content: "\e306";
}
.slicon-hand-card:before {
  content: "\e307";
}
.slicon-hand-coin:before {
  content: "\e308";
}
.slicon-keynote:before {
  content: "\e309";
}
.slicon-master-card:before {
  content: "\e30a";
}
.slicon-money:before {
  content: "\e30b";
}
.slicon-parking-meter:before {
  content: "\e30c";
}
.slicon-percent-1:before {
  content: "\e30d";
}
.slicon-percent-2:before {
  content: "\e30e";
}
.slicon-percent-3:before {
  content: "\e30f";
}
.slicon-percent-4:before {
  content: "\e310";
}
.slicon-percent-5:before {
  content: "\e311";
}
.slicon-percent-up:before {
  content: "\e312";
}
.slicon-pie-chart-1:before {
  content: "\e313";
}
.slicon-pie-chart-2:before {
  content: "\e314";
}
.slicon-piggy-bank:before {
  content: "\e315";
}
.slicon-pound-currency-1:before {
  content: "\e316";
}
.slicon-pound-currency-2:before {
  content: "\e317";
}
.slicon-pound-currency-3:before {
  content: "\e318";
}
.slicon-pound-currency-4:before {
  content: "\e319";
}
.slicon-safe-1:before {
  content: "\e31a";
}
.slicon-safe-2:before {
  content: "\e31b";
}
.slicon-shop:before {
  content: "\e31c";
}
.slicon-sign:before {
  content: "\e31d";
}
.slicon-trolley:before {
  content: "\e31e";
}
.slicon-truck-1:before {
  content: "\e31f";
}
.slicon-truck-2:before {
  content: "\e320";
}
.slicon-visa-card:before {
  content: "\e321";
}
.slicon-yen-currency-1:before {
  content: "\e322";
}
.slicon-yen-currency-2:before {
  content: "\e323";
}
.slicon-yen-currency-3:before {
  content: "\e324";
}
.slicon-yen-currency-4:before {
  content: "\e325";
}
.slicon-add-marker-1:before {
  content: "\e326";
}
.slicon-add-marker-1-1:before {
  content: "\e327";
}
.slicon-add-marker-2:before {
  content: "\e328";
}
.slicon-add-marker-2-1:before {
  content: "\e329";
}
.slicon-add-marker-3:before {
  content: "\e32a";
}
.slicon-compass-1:before {
  content: "\e32b";
}
.slicon-compass-2:before {
  content: "\e32c";
}
.slicon-compass-3:before {
  content: "\e32d";
}
.slicon-delete-marker-1:before {
  content: "\e32e";
}
.slicon-delete-marker-1-1:before {
  content: "\e32f";
}
.slicon-delete-marker-2:before {
  content: "\e330";
}
.slicon-delete-marker-2-1:before {
  content: "\e331";
}
.slicon-favorite-marker-1:before {
  content: "\e332";
}
.slicon-favorite-marker-1-1:before {
  content: "\e333";
}
.slicon-favorite-marker-2:before {
  content: "\e334";
}
.slicon-favorite-marker-2-1:before {
  content: "\e335";
}
.slicon-globe:before {
  content: "\e336";
}
.slicon-location:before {
  content: "\e337";
}
.slicon-map-1:before {
  content: "\e338";
}
.slicon-map-location:before {
  content: "\e339";
}
.slicon-map-marker-1:before {
  content: "\e33a";
}
.slicon-map-marker-1-1:before {
  content: "\e33b";
}
.slicon-map-marker-2:before {
  content: "\e33c";
}
.slicon-map-marker-3:before {
  content: "\e33d";
}
.slicon-map-marker-pin:before {
  content: "\e33e";
}
.slicon-map-pin:before {
  content: "\e33f";
}
.slicon-marker-1:before {
  content: "\e340";
}
.slicon-marker-1-1:before {
  content: "\e341";
}
.slicon-marker-2:before {
  content: "\e342";
}
.slicon-marker-2-1:before {
  content: "\e343";
}
.slicon-marker-pin-1:before {
  content: "\e344";
}
.slicon-marker-pin-2:before {
  content: "\e345";
}
.slicon-marker-pin-location:before {
  content: "\e346";
}
.slicon-minus-marker-1:before {
  content: "\e347";
}
.slicon-minus-marker-1-1:before {
  content: "\e348";
}
.slicon-minus-marker-2:before {
  content: "\e349";
}
.slicon-minus-marker-2-1:before {
  content: "\e34a";
}
.slicon-minus-marker-3:before {
  content: "\e34b";
}
.slicon-anchor:before {
  content: "\e34c";
}
.slicon-bank:before {
  content: "\e34d";
}
.slicon-beach:before {
  content: "\e34e";
}
.slicon-boat:before {
  content: "\e34f";
}
.slicon-building-1:before {
  content: "\e350";
}
.slicon-building-2:before {
  content: "\e351";
}
.slicon-building-3:before {
  content: "\e352";
}
.slicon-buildings-1:before {
  content: "\e353";
}
.slicon-buildings-2:before {
  content: "\e354";
}
.slicon-buildings-3:before {
  content: "\e355";
}
.slicon-buildings-4:before {
  content: "\e356";
}
.slicon-castle:before {
  content: "\e357";
}
.slicon-column:before {
  content: "\e358";
}
.slicon-direction-sign:before {
  content: "\e359";
}
.slicon-factory:before {
  content: "\e35a";
}
.slicon-fence:before {
  content: "\e35b";
}
.slicon-garage:before {
  content: "\e35c";
}
.slicon-globe-1:before {
  content: "\e35d";
}
.slicon-globe-2:before {
  content: "\e35e";
}
.slicon-house-1:before {
  content: "\e35f";
}
.slicon-house-2:before {
  content: "\e360";
}
.slicon-house-3:before {
  content: "\e361";
}
.slicon-house-4:before {
  content: "\e362";
}
.slicon-library:before {
  content: "\e363";
}
.slicon-light-house:before {
  content: "\e364";
}
.slicon-pisa:before {
  content: "\e365";
}
.slicon-skyscraper:before {
  content: "\e366";
}
.slicon-temple:before {
  content: "\e367";
}
.slicon-treasure-map:before {
  content: "\e368";
}
.slicon-tree:before {
  content: "\e369";
}
.slicon-tree-pine:before {
  content: "\e36a";
}
.slicon-attention:before {
  content: "\e36b";
}
.slicon-bug-1:before {
  content: "\e36c";
}
.slicon-bug-2:before {
  content: "\e36d";
}
.slicon-css3:before {
  content: "\e36e";
}
.slicon-firewall:before {
  content: "\e36f";
}
.slicon-html5:before {
  content: "\e370";
}
.slicon-plugins-1:before {
  content: "\e371";
}
.slicon-plugins-2:before {
  content: "\e372";
}
.slicon-script:before {
  content: "\e373";
}
.slicon-new-window:before {
  content: "\e374";
}
.slicon-window-1:before {
  content: "\e375";
}
.slicon-window-2:before {
  content: "\e376";
}
.slicon-window-3:before {
  content: "\e377";
}
.slicon-window-add:before {
  content: "\e378";
}
.slicon-window-alert:before {
  content: "\e379";
}
.slicon-window-check:before {
  content: "\e37a";
}
.slicon-window-code-1:before {
  content: "\e37b";
}
.slicon-window-code-2:before {
  content: "\e37c";
}
.slicon-window-code-3:before {
  content: "\e37d";
}
.slicon-window-column:before {
  content: "\e37e";
}
.slicon-window-delete:before {
  content: "\e37f";
}
.slicon-window-denied:before {
  content: "\e380";
}
.slicon-window-download-1:before {
  content: "\e381";
}
.slicon-window-download-2:before {
  content: "\e382";
}
.slicon-window-edit:before {
  content: "\e383";
}
.slicon-window-favorite:before {
  content: "\e384";
}
.slicon-window-graph-1:before {
  content: "\e385";
}
.slicon-window-graph-2:before {
  content: "\e386";
}
.slicon-window-hand:before {
  content: "\e387";
}
.slicon-window-home:before {
  content: "\e388";
}
.slicon-window-like:before {
  content: "\e389";
}
.slicon-window-list-1:before {
  content: "\e38a";
}
.slicon-window-list-2:before {
  content: "\e38b";
}
.slicon-window-lock:before {
  content: "\e38c";
}
.slicon-window-minimize:before {
  content: "\e38d";
}
.slicon-window-minus:before {
  content: "\e38e";
}
.slicon-window-refresh:before {
  content: "\e38f";
}
.slicon-window-register:before {
  content: "\e390";
}
.slicon-window-search:before {
  content: "\e391";
}
.slicon-window-selection:before {
  content: "\e392";
}
.slicon-window-setting:before {
  content: "\e393";
}
.slicon-window-sync:before {
  content: "\e394";
}
.slicon-window-thumbnails-1:before {
  content: "\e395";
}
.slicon-window-thumbnails-2:before {
  content: "\e396";
}
.slicon-window-time:before {
  content: "\e397";
}
.slicon-window-upload-1:before {
  content: "\e398";
}
.slicon-window-upload-2:before {
  content: "\e399";
}
.slicon-windows-selection:before {
  content: "\e39a";
}
.slicon-database:before {
  content: "\e39b";
}
.slicon-database-alert:before {
  content: "\e39c";
}
.slicon-database-block:before {
  content: "\e39d";
}
.slicon-database-check:before {
  content: "\e39e";
}
.slicon-database-delete:before {
  content: "\e39f";
}
.slicon-database-download:before {
  content: "\e3a0";
}
.slicon-database-edit:before {
  content: "\e3a1";
}
.slicon-database-lock:before {
  content: "\e3a2";
}
.slicon-database-minus:before {
  content: "\e3a3";
}
.slicon-database-network:before {
  content: "\e3a4";
}
.slicon-database-plus:before {
  content: "\e3a5";
}
.slicon-database-refresh:before {
  content: "\e3a6";
}
.slicon-database-search:before {
  content: "\e3a7";
}
.slicon-database-setting:before {
  content: "\e3a8";
}
.slicon-database-sync:before {
  content: "\e3a9";
}
.slicon-database-time:before {
  content: "\e3aa";
}
.slicon-database-upload:before {
  content: "\e3ab";
}
.slicon-battery-charging:before {
  content: "\e3ac";
}
.slicon-battery-full:before {
  content: "\e3ad";
}
.slicon-battery-high:before {
  content: "\e3ae";
}
.slicon-battery-low:before {
  content: "\e3af";
}
.slicon-battery-medium:before {
  content: "\e3b0";
}
.slicon-cd-1:before {
  content: "\e3b1";
}
.slicon-cd-2:before {
  content: "\e3b2";
}
.slicon-chip:before {
  content: "\e3b3";
}
.slicon-computer:before {
  content: "\e3b4";
}
.slicon-disc:before {
  content: "\e3b5";
}
.slicon-filter:before {
  content: "\e3b6";
}
.slicon-floppy-disk:before {
  content: "\e3b7";
}
.slicon-gameboy:before {
  content: "\e3b8";
}
.slicon-harddisk-1:before {
  content: "\e3b9";
}
.slicon-harddisk-2:before {
  content: "\e3ba";
}
.slicon-imac:before {
  content: "\e3bb";
}
.slicon-ipad-1:before {
  content: "\e3bc";
}
.slicon-ipad-2:before {
  content: "\e3bd";
}
.slicon-ipod:before {
  content: "\e3be";
}
.slicon-joystick-1:before {
  content: "\e3bf";
}
.slicon-joystick-2:before {
  content: "\e3c0";
}
.slicon-joystick-3:before {
  content: "\e3c1";
}
.slicon-keyboard-1:before {
  content: "\e3c2";
}
.slicon-keyboard-2:before {
  content: "\e3c3";
}
.slicon-kindle-1:before {
  content: "\e3c4";
}
.slicon-kindle-2:before {
  content: "\e3c5";
}
.slicon-laptop-1:before {
  content: "\e3c6";
}
.slicon-laptop-2:before {
  content: "\e3c7";
}
.slicon-memory-card:before {
  content: "\e3c8";
}
.slicon-mobile-phone:before {
  content: "\e3c9";
}
.slicon-mouse-1:before {
  content: "\e3ca";
}
.slicon-mouse-2:before {
  content: "\e3cb";
}
.slicon-mp3player:before {
  content: "\e3cc";
}
.slicon-plug-1:before {
  content: "\e3cd";
}
.slicon-plug-2:before {
  content: "\e3ce";
}
.slicon-plug-slot:before {
  content: "\e3cf";
}
.slicon-printer:before {
  content: "\e3d0";
}
.slicon-projector:before {
  content: "\e3d1";
}
.slicon-remote:before {
  content: "\e3d2";
}
.slicon-router:before {
  content: "\e3d3";
}
.slicon-screen-1:before {
  content: "\e3d4";
}
.slicon-screen-2:before {
  content: "\e3d5";
}
.slicon-screen-3:before {
  content: "\e3d6";
}
.slicon-screen-4:before {
  content: "\e3d7";
}
.slicon-smartphone-1:before {
  content: "\e3d8";
}
.slicon-television-1:before {
  content: "\e3d9";
}
.slicon-typewriter-1:before {
  content: "\e3da";
}
.slicon-typewriter-2:before {
  content: "\e3db";
}
.slicon-usb-1:before {
  content: "\e3dc";
}
.slicon-usb-2:before {
  content: "\e3dd";
}
.slicon-webcam:before {
  content: "\e3de";
}
.slicon-wireless-router-1:before {
  content: "\e3df";
}
.slicon-wireless-router-2:before {
  content: "\e3e0";
}
.slicon-bluetooth:before {
  content: "\e3e1";
}
.slicon-ethernet:before {
  content: "\e3e2";
}
.slicon-ethernet-slot:before {
  content: "\e3e3";
}
.slicon-firewire-1:before {
  content: "\e3e4";
}
.slicon-firewire-2:before {
  content: "\e3e5";
}
.slicon-network-1:before {
  content: "\e3e6";
}
.slicon-network-2:before {
  content: "\e3e7";
}
.slicon-server-1:before {
  content: "\e3e8";
}
.slicon-server-2:before {
  content: "\e3e9";
}
.slicon-server-3:before {
  content: "\e3ea";
}
.slicon-usb:before {
  content: "\e3eb";
}
.slicon-wireless-signal:before {
  content: "\e3ec";
}
.slicon-book-1:before {
  content: "\e3ed";
}
.slicon-book-2:before {
  content: "\e3ee";
}
.slicon-book-3:before {
  content: "\e3ef";
}
.slicon-book-4:before {
  content: "\e3f0";
}
.slicon-book-5:before {
  content: "\e3f1";
}
.slicon-book-6:before {
  content: "\e3f2";
}
.slicon-book-7:before {
  content: "\e3f3";
}
.slicon-book-8:before {
  content: "\e3f4";
}
.slicon-book-download-1:before {
  content: "\e3f5";
}
.slicon-book-download-2:before {
  content: "\e3f6";
}
.slicon-book-favorite-1:before {
  content: "\e3f7";
}
.slicon-book-favorite-2:before {
  content: "\e3f8";
}
.slicon-bookmark-1-1:before {
  content: "\e3f9";
}
.slicon-bookmark-2-1:before {
  content: "\e3fa";
}
.slicon-bookmark-3-1:before {
  content: "\e3fb";
}
.slicon-bookmark-4-1:before {
  content: "\e3fc";
}
.slicon-books-1:before {
  content: "\e3fd";
}
.slicon-books-2:before {
  content: "\e3fe";
}
.slicon-books-3:before {
  content: "\e3ff";
}
.slicon-briefcase-2:before {
  content: "\e400";
}
.slicon-contact-book-1:before {
  content: "\e401";
}
.slicon-contact-book-2:before {
  content: "\e402";
}
.slicon-contact-book-3:before {
  content: "\e403";
}
.slicon-contact-book-4:before {
  content: "\e404";
}
.slicon-copyright:before {
  content: "\e405";
}
.slicon-creative-commons:before {
  content: "\e406";
}
.slicon-cube:before {
  content: "\e407";
}
.slicon-data-filter:before {
  content: "\e408";
}
.slicon-document-box-1:before {
  content: "\e409";
}
.slicon-document-box-2:before {
  content: "\e40a";
}
.slicon-document-box-3:before {
  content: "\e40b";
}
.slicon-drawer-1:before {
  content: "\e40c";
}
.slicon-drawer-2:before {
  content: "\e40d";
}
.slicon-drawer-3:before {
  content: "\e40e";
}
.slicon-envelope:before {
  content: "\e40f";
}
.slicon-file:before {
  content: "\e410";
}
.slicon-files:before {
  content: "\e411";
}
.slicon-filter-1:before {
  content: "\e412";
}
.slicon-filter-2:before {
  content: "\e413";
}
.slicon-layers-1:before {
  content: "\e414";
}
.slicon-list-1:before {
  content: "\e415";
}
.slicon-list-2:before {
  content: "\e416";
}
.slicon-newspaper-1:before {
  content: "\e417";
}
.slicon-newspaper-2:before {
  content: "\e418";
}
.slicon-registry-1:before {
  content: "\e419";
}
.slicon-registry-2:before {
  content: "\e41a";
}
.slicon-shield-1:before {
  content: "\e41b";
}
.slicon-shield-2:before {
  content: "\e41c";
}
.slicon-shield-3:before {
  content: "\e41d";
}
.slicon-sketchbook:before {
  content: "\e41e";
}
.slicon-sound-book:before {
  content: "\e41f";
}
.slicon-thumbnails-1:before {
  content: "\e420";
}
.slicon-thumbnails-2:before {
  content: "\e421";
}
.slicon-graph:before {
  content: "\e422";
}
.slicon-hierarchy-1:before {
  content: "\e423";
}
.slicon-hierarchy-2:before {
  content: "\e424";
}
.slicon-hierarchy-3:before {
  content: "\e425";
}
.slicon-hierarchy-4:before {
  content: "\e426";
}
.slicon-hierarchy-5:before {
  content: "\e427";
}
.slicon-hierarchy-6:before {
  content: "\e428";
}
.slicon-hierarchy-7:before {
  content: "\e429";
}
.slicon-network-1-1:before {
  content: "\e42a";
}
.slicon-network-2-1:before {
  content: "\e42b";
}
.slicon-backpack:before {
  content: "\e42c";
}
.slicon-balance-1:before {
  content: "\e42d";
}
.slicon-bed:before {
  content: "\e42e";
}
.slicon-bench:before {
  content: "\e42f";
}
.slicon-bomb-1:before {
  content: "\e430";
}
.slicon-bricks:before {
  content: "\e431";
}
.slicon-bullets:before {
  content: "\e432";
}
.slicon-buoy:before {
  content: "\e433";
}
.slicon-campfire:before {
  content: "\e434";
}
.slicon-can:before {
  content: "\e435";
}
.slicon-candle:before {
  content: "\e436";
}
.slicon-canon:before {
  content: "\e437";
}
.slicon-cctv-1:before {
  content: "\e438";
}
.slicon-cctv-2:before {
  content: "\e439";
}
.slicon-chair:before {
  content: "\e43a";
}
.slicon-chair-director:before {
  content: "\e43b";
}
.slicon-cigarette:before {
  content: "\e43c";
}
.slicon-construction-sign:before {
  content: "\e43d";
}
.slicon-diamond:before {
  content: "\e43e";
}
.slicon-disabled:before {
  content: "\e43f";
}
.slicon-door:before {
  content: "\e440";
}
.slicon-drawer:before {
  content: "\e441";
}
.slicon-driller:before {
  content: "\e442";
}
.slicon-dumbbells:before {
  content: "\e443";
}
.slicon-fire-extinguisher:before {
  content: "\e444";
}
.slicon-flashlight:before {
  content: "\e445";
}
.slicon-gas-station:before {
  content: "\e446";
}
.slicon-gun:before {
  content: "\e447";
}
.slicon-lamp-1:before {
  content: "\e448";
}
.slicon-lamp-2:before {
  content: "\e449";
}
.slicon-lamp-3:before {
  content: "\e44a";
}
.slicon-lamp-4:before {
  content: "\e44b";
}
.slicon-lightbulb-1:before {
  content: "\e44c";
}
.slicon-lightbulb-2:before {
  content: "\e44d";
}
.slicon-measuring-tape:before {
  content: "\e44e";
}
.slicon-mine-cart:before {
  content: "\e44f";
}
.slicon-missile:before {
  content: "\e450";
}
.slicon-ring:before {
  content: "\e451";
}
.slicon-scale-1:before {
  content: "\e452";
}
.slicon-shovel:before {
  content: "\e453";
}
.slicon-smoke-no:before {
  content: "\e454";
}
.slicon-sofa-1:before {
  content: "\e455";
}
.slicon-sofa-2:before {
  content: "\e456";
}
.slicon-sofa-3:before {
  content: "\e457";
}
.slicon-target:before {
  content: "\e458";
}
.slicon-torch:before {
  content: "\e459";
}
.slicon-traffic-cone:before {
  content: "\e45a";
}
.slicon-traffic-light-1:before {
  content: "\e45b";
}
.slicon-traffic-light-2:before {
  content: "\e45c";
}
.slicon-treasure-chest-1:before {
  content: "\e45d";
}
.slicon-treasure-chest-2:before {
  content: "\e45e";
}
.slicon-trowel:before {
  content: "\e45f";
}
.slicon-watering-can:before {
  content: "\e460";
}
.slicon-weigh:before {
  content: "\e461";
}
.slicon-36-text-arrow-redo:before {
  content: "\e462";
}
.slicon-academic-cap:before {
  content: "\e463";
}
.slicon-baseball-helmet:before {
  content: "\e464";
}
.slicon-beanie:before {
  content: "\e465";
}
.slicon-bike-helmet:before {
  content: "\e466";
}
.slicon-bow:before {
  content: "\e467";
}
.slicon-cap:before {
  content: "\e468";
}
.slicon-chaplin:before {
  content: "\e469";
}
.slicon-chef-hat:before {
  content: "\e46a";
}
.slicon-cloth-hanger:before {
  content: "\e46b";
}
.slicon-fins:before {
  content: "\e46c";
}
.slicon-football-helmet:before {
  content: "\e46d";
}
.slicon-glasses:before {
  content: "\e46e";
}
.slicon-glasses-1:before {
  content: "\e46f";
}
.slicon-glasses-2:before {
  content: "\e470";
}
.slicon-magician-hat:before {
  content: "\e471";
}
.slicon-monocle-1:before {
  content: "\e472";
}
.slicon-monocle-2:before {
  content: "\e473";
}
.slicon-necktie:before {
  content: "\e474";
}
.slicon-safety-helmet:before {
  content: "\e475";
}
.slicon-scuba-tank:before {
  content: "\e476";
}
.slicon-shirt-1:before {
  content: "\e477";
}
.slicon-shirt-2:before {
  content: "\e478";
}
.slicon-shirt-3:before {
  content: "\e479";
}
.slicon-sneakers:before {
  content: "\e47a";
}
.slicon-snorkel:before {
  content: "\e47b";
}
.slicon-sombrero:before {
  content: "\e47c";
}
.slicon-sunglasses:before {
  content: "\e47d";
}
.slicon-tall-hat:before {
  content: "\e47e";
}
.slicon-trousers:before {
  content: "\e47f";
}
.slicon-walking-stick:before {
  content: "\e480";
}
.slicon-arrow-undo:before {
  content: "\e481";
}
.slicon-bold:before {
  content: "\e482";
}
.slicon-columns:before {
  content: "\e483";
}
.slicon-eraser:before {
  content: "\e484";
}
.slicon-font-color:before {
  content: "\e485";
}
.slicon-html:before {
  content: "\e486";
}
.slicon-italic:before {
  content: "\e487";
}
.slicon-list-1-1:before {
  content: "\e488";
}
.slicon-list-2-1:before {
  content: "\e489";
}
.slicon-list-3:before {
  content: "\e48a";
}
.slicon-list-4:before {
  content: "\e48b";
}
.slicon-paragraph:before {
  content: "\e48c";
}
.slicon-paste:before {
  content: "\e48d";
}
.slicon-print-preview:before {
  content: "\e48e";
}
.slicon-quote:before {
  content: "\e48f";
}
.slicon-strikethrough:before {
  content: "\e490";
}
.slicon-text:before {
  content: "\e491";
}
.slicon-text-wrapping-1:before {
  content: "\e492";
}
.slicon-text-wrapping-2:before {
  content: "\e493";
}
.slicon-text-wrapping-3:before {
  content: "\e494";
}
.slicon-underline:before {
  content: "\e495";
}
.slicon-align-center:before {
  content: "\e496";
}
.slicon-align-left:before {
  content: "\e497";
}
.slicon-align-right:before {
  content: "\e498";
}
.slicon-all-caps:before {
  content: "\e499";
}
.slicon-arrange-2-1:before {
  content: "\e49a";
}
.slicon-arrange-2-2:before {
  content: "\e49b";
}
.slicon-arrange-2-3:before {
  content: "\e49c";
}
.slicon-arrange-2-4:before {
  content: "\e49d";
}
.slicon-arrange-3-1:before {
  content: "\e49e";
}
.slicon-arrange-3-2:before {
  content: "\e49f";
}
.slicon-arrange-3-3:before {
  content: "\e4a0";
}
.slicon-arrange-3-4:before {
  content: "\e4a1";
}
.slicon-arrange-3-5:before {
  content: "\e4a2";
}
.slicon-arrange-4-1:before {
  content: "\e4a3";
}
.slicon-arrange-4-2:before {
  content: "\e4a4";
}
.slicon-arrange-4-3:before {
  content: "\e4a5";
}
.slicon-arrange-5:before {
  content: "\e4a6";
}
.slicon-consolidate-all:before {
  content: "\e4a7";
}
.slicon-decrease-indent-1:before {
  content: "\e4a8";
}
.slicon-decrease-indent-2:before {
  content: "\e4a9";
}
.slicon-horizontal-page:before {
  content: "\e4aa";
}
.slicon-increase-indent-1:before {
  content: "\e4ab";
}
.slicon-increase-indent-2:before {
  content: "\e4ac";
}
.slicon-justify:before {
  content: "\e4ad";
}
.slicon-leading-1:before {
  content: "\e4ae";
}
.slicon-leading-2:before {
  content: "\e4af";
}
.slicon-left-indent:before {
  content: "\e4b0";
}
.slicon-right-indent:before {
  content: "\e4b1";
}
.slicon-small-caps:before {
  content: "\e4b2";
}
.slicon-vertical-page:before {
  content: "\e4b3";
}
.slicon-alt-mac:before {
  content: "\e4b4";
}
.slicon-alt-windows:before {
  content: "\e4b5";
}
.slicon-arrow-down:before {
  content: "\e4b6";
}
.slicon-arrow-down-left:before {
  content: "\e4b7";
}
.slicon-arrow-down-right:before {
  content: "\e4b8";
}
.slicon-arrow-left:before {
  content: "\e4b9";
}
.slicon-arrow-right:before {
  content: "\e4ba";
}
.slicon-arrow-up:before {
  content: "\e4bb";
}
.slicon-arrow-up-left:before {
  content: "\e4bc";
}
.slicon-arrow-up-right:before {
  content: "\e4bd";
}
.slicon-asterisk-1:before {
  content: "\e4be";
}
.slicon-asterisk-2:before {
  content: "\e4bf";
}
.slicon-back-tab-1:before {
  content: "\e4c0";
}
.slicon-back-tab-2:before {
  content: "\e4c1";
}
.slicon-backward-delete:before {
  content: "\e4c2";
}
.slicon-blank:before {
  content: "\e4c3";
}
.slicon-eject:before {
  content: "\e4c4";
}
.slicon-enter-1:before {
  content: "\e4c5";
}
.slicon-enter-2:before {
  content: "\e4c6";
}
.slicon-escape:before {
  content: "\e4c7";
}
.slicon-page-down:before {
  content: "\e4c8";
}
.slicon-page-up:before {
  content: "\e4c9";
}
.slicon-return-1:before {
  content: "\e4ca";
}
.slicon-shift-1:before {
  content: "\e4cb";
}
.slicon-shift-2:before {
  content: "\e4cc";
}
.slicon-tab:before {
  content: "\e4cd";
}
.slicon-apple:before {
  content: "\e4ce";
}
.slicon-beer:before {
  content: "\e4cf";
}
.slicon-boil:before {
  content: "\e4d0";
}
.slicon-bottle-1:before {
  content: "\e4d1";
}
.slicon-bottle-2:before {
  content: "\e4d2";
}
.slicon-bottle-3:before {
  content: "\e4d3";
}
.slicon-bottle-4:before {
  content: "\e4d4";
}
.slicon-bread:before {
  content: "\e4d5";
}
.slicon-burger-1:before {
  content: "\e4d6";
}
.slicon-burger-2:before {
  content: "\e4d7";
}
.slicon-cake-1:before {
  content: "\e4d8";
}
.slicon-cake-2:before {
  content: "\e4d9";
}
.slicon-champagne:before {
  content: "\e4da";
}
.slicon-cheese:before {
  content: "\e4db";
}
.slicon-cocktail:before {
  content: "\e4dc";
}
.slicon-cocktail-1:before {
  content: "\e4dd";
}
.slicon-cocktail-2:before {
  content: "\e4de";
}
.slicon-coffee-pot:before {
  content: "\e4df";
}
.slicon-cup1:before {
  content: "\e4e0";
}
.slicon-cup-2:before {
  content: "\e4e1";
}
.slicon-deep-fry:before {
  content: "\e4e2";
}
.slicon-energy-drink:before {
  content: "\e4e3";
}
.slicon-espresso-machine:before {
  content: "\e4e4";
}
.slicon-food-dome:before {
  content: "\e4e5";
}
.slicon-fork-and-knife:before {
  content: "\e4e6";
}
.slicon-fork-and-spoon:before {
  content: "\e4e7";
}
.slicon-grape:before {
  content: "\e4e8";
}
.slicon-grater:before {
  content: "\e4e9";
}
.slicon-grill:before {
  content: "\e4ea";
}
.slicon-hot-drinks-glass:before {
  content: "\e4eb";
}
.slicon-hotdog:before {
  content: "\e4ec";
}
.slicon-ice-cream-1:before {
  content: "\e4ed";
}
.slicon-ice-cream-2:before {
  content: "\e4ee";
}
.slicon-ice-cream-3:before {
  content: "\e4ef";
}
.slicon-ice-drinks-galss:before {
  content: "\e4f0";
}
.slicon-juicer:before {
  content: "\e4f1";
}
.slicon-kitchen-timer:before {
  content: "\e4f2";
}
.slicon-milk:before {
  content: "\e4f3";
}
.slicon-orange:before {
  content: "\e4f4";
}
.slicon-oven:before {
  content: "\e4f5";
}
.slicon-pan-fry:before {
  content: "\e4f6";
}
.slicon-pepper-salt:before {
  content: "\e4f7";
}
.slicon-pizza:before {
  content: "\e4f8";
}
.slicon-popcorn:before {
  content: "\e4f9";
}
.slicon-serving:before {
  content: "\e4fa";
}
.slicon-soda:before {
  content: "\e4fb";
}
.slicon-soda-can-1:before {
  content: "\e4fc";
}
.slicon-soda-can-2:before {
  content: "\e4fd";
}
.slicon-steam:before {
  content: "\e4fe";
}
.slicon-tea-pot:before {
  content: "\e4ff";
}
.slicon-thermometer-high:before {
  content: "\e500";
}
.slicon-thermometer-low:before {
  content: "\e501";
}
.slicon-thermometer-medium:before {
  content: "\e502";
}
.slicon-water:before {
  content: "\e503";
}
.slicon-wine:before {
  content: "\e504";
}
.slicon-ambulance:before {
  content: "\e505";
}
.slicon-beaker-1:before {
  content: "\e506";
}
.slicon-beaker-2:before {
  content: "\e507";
}
.slicon-blood:before {
  content: "\e508";
}
.slicon-drug:before {
  content: "\e509";
}
.slicon-first-aid:before {
  content: "\e50a";
}
.slicon-hashish:before {
  content: "\e50b";
}
.slicon-heartpulse:before {
  content: "\e50c";
}
.slicon-hospital-1:before {
  content: "\e50d";
}
.slicon-hospital-2:before {
  content: "\e50e";
}
.slicon-hospital-sign-1:before {
  content: "\e50f";
}
.slicon-hospital-sign-2:before {
  content: "\e510";
}
.slicon-hospital-sign-3:before {
  content: "\e511";
}
.slicon-medicine:before {
  content: "\e512";
}
.slicon-microscope:before {
  content: "\e513";
}
.slicon-mortar-and-pestle:before {
  content: "\e514";
}
.slicon-plaster:before {
  content: "\e515";
}
.slicon-pulse-graph-1:before {
  content: "\e516";
}
.slicon-pulse-graph-2:before {
  content: "\e517";
}
.slicon-pulse-graph-3:before {
  content: "\e518";
}
.slicon-red-cross:before {
  content: "\e519";
}
.slicon-stethoscope:before {
  content: "\e51a";
}
.slicon-syringe:before {
  content: "\e51b";
}
.slicon-yin-yang:before {
  content: "\e51c";
}
.slicon-balloon:before {
  content: "\e51d";
}
.slicon-briefcase-lock:before {
  content: "\e51e";
}
.slicon-card:before {
  content: "\e51f";
}
.slicon-cards:before {
  content: "\e520";
}
.slicon-cards-2:before {
  content: "\e521";
}
.slicon-curtain:before {
  content: "\e522";
}
.slicon-dice-1:before {
  content: "\e523";
}
.slicon-dice-2:before {
  content: "\e524";
}
.slicon-happy-smiley:before {
  content: "\e525";
}
.slicon-pacman:before {
  content: "\e526";
}
.slicon-pacman-ghost:before {
  content: "\e527";
}
.slicon-sad-smiley:before {
  content: "\e528";
}
.slicon-sign-1:before {
  content: "\e529";
}
.slicon-smileys:before {
  content: "\e52a";
}
.slicon-suitcase-1:before {
  content: "\e52b";
}
.slicon-suitcase-2:before {
  content: "\e52c";
}
.slicon-tetris:before {
  content: "\e52d";
}
.slicon-ticket-1:before {
  content: "\e52e";
}
.slicon-ticket-2:before {
  content: "\e52f";
}
.slicon-ticket-3:before {
  content: "\e530";
}
.slicon-virus:before {
  content: "\e531";
}
.slicon-cloud-1:before {
  content: "\e532";
}
.slicon-cloud-lightning:before {
  content: "\e533";
}
.slicon-clouds:before {
  content: "\e534";
}
.slicon-first-quarter-moon:before {
  content: "\e535";
}
.slicon-full-moon:before {
  content: "\e536";
}
.slicon-hail:before {
  content: "\e537";
}
.slicon-heavy-rain:before {
  content: "\e538";
}
.slicon-moon-cloud:before {
  content: "\e539";
}
.slicon-rain:before {
  content: "\e53a";
}
.slicon-rain-lightning:before {
  content: "\e53b";
}
.slicon-snow:before {
  content: "\e53c";
}
.slicon-sun:before {
  content: "\e53d";
}
.slicon-sun-cloud:before {
  content: "\e53e";
}
.slicon-thermometer:before {
  content: "\e53f";
}
.slicon-third-quarter-moon:before {
  content: "\e540";
}
.slicon-umbrella:before {
  content: "\e541";
}
.slicon-waning-crescent-moon:before {
  content: "\e542";
}
.slicon-waning-gibbous-moon:before {
  content: "\e543";
}
.slicon-waxing-crescent-moon:before {
  content: "\e544";
}
.slicon-waxing-gibbous-moon:before {
  content: "\e545";
}
.slicon-bicycle:before {
  content: "\e546";
}
.slicon-bus-1:before {
  content: "\e547";
}
.slicon-bus-2:before {
  content: "\e548";
}
.slicon-car-1:before {
  content: "\e549";
}
.slicon-car-2:before {
  content: "\e54a";
}
.slicon-car-3:before {
  content: "\e54b";
}
.slicon-car-4:before {
  content: "\e54c";
}
.slicon-helicopter:before {
  content: "\e54d";
}
.slicon-mountain-bike:before {
  content: "\e54e";
}
.slicon-pickup:before {
  content: "\e54f";
}
.slicon-plane-1:before {
  content: "\e550";
}
.slicon-plane-2:before {
  content: "\e551";
}
.slicon-plane-landing:before {
  content: "\e552";
}
.slicon-plane-takeoff:before {
  content: "\e553";
}
.slicon-road:before {
  content: "\e554";
}
.slicon-road-bike:before {
  content: "\e555";
}
.slicon-rocket:before {
  content: "\e556";
}
.slicon-scooter:before {
  content: "\e557";
}
.slicon-ship:before {
  content: "\e558";
}
.slicon-train:before {
  content: "\e559";
}
.slicon-tram:before {
  content: "\e55a";
}
.slicon-cactus:before {
  content: "\e55b";
}
.slicon-clover:before {
  content: "\e55c";
}
.slicon-flower:before {
  content: "\e55d";
}
.slicon-hand-eco:before {
  content: "\e55e";
}
.slicon-hand-globe:before {
  content: "\e55f";
}
.slicon-leaf:before {
  content: "\e560";
}
.slicon-light-eco:before {
  content: "\e561";
}
.slicon-potted-plant-1:before {
  content: "\e562";
}
.slicon-potted-plant-2:before {
  content: "\e563";
}
.slicon-2-fingers-double-tap:before {
  content: "\e564";
}
.slicon-2-fingers-down-swipe:before {
  content: "\e565";
}
.slicon-2-fingers-horizontal-swipe:before {
  content: "\e566";
}
.slicon-2-fingers-left-swipe:before {
  content: "\e567";
}
.slicon-2-fingers-omnidirectional-swipe:before {
  content: "\e568";
}
.slicon-2-fingers-right-swipe:before {
  content: "\e569";
}
.slicon-2-fingers-tab-hold:before {
  content: "\e56a";
}
.slicon-2-fingers-tap:before {
  content: "\e56b";
}
.slicon-2-fingers-up-swipe:before {
  content: "\e56c";
}
.slicon-2-fingers-vertical-swipe:before {
  content: "\e56d";
}
.slicon-double-tap:before {
  content: "\e56e";
}
.slicon-drag-down:before {
  content: "\e56f";
}
.slicon-drag-horizontal:before {
  content: "\e570";
}
.slicon-drag-left:before {
  content: "\e571";
}
.slicon-drag-right:before {
  content: "\e572";
}
.slicon-drag-up:before {
  content: "\e573";
}
.slicon-drag-vertical:before {
  content: "\e574";
}
.slicon-filck-down:before {
  content: "\e575";
}
.slicon-flick-up:before {
  content: "\e576";
}
.slicon-horizontal-flick:before {
  content: "\e577";
}
.slicon-left-flick:before {
  content: "\e578";
}
.slicon-omnidirectional-drag:before {
  content: "\e579";
}
.slicon-omnidirectional-flick:before {
  content: "\e57a";
}
.slicon-omnidirectional-swipe:before {
  content: "\e57b";
}
.slicon-pinch:before {
  content: "\e57c";
}
.slicon-right-flick:before {
  content: "\e57d";
}
.slicon-rotate-clockwise:before {
  content: "\e57e";
}
.slicon-rotate-counterclockwise:before {
  content: "\e57f";
}
.slicon-spread:before {
  content: "\e580";
}
.slicon-swipe-down:before {
  content: "\e581";
}
.slicon-swipe-horizontal:before {
  content: "\e582";
}
.slicon-swipe-left:before {
  content: "\e583";
}
.slicon-swipe-right:before {
  content: "\e584";
}
.slicon-swipe-up:before {
  content: "\e585";
}
.slicon-swipe-vertical:before {
  content: "\e586";
}
.slicon-tap:before {
  content: "\e587";
}
.slicon-tap-hold:before {
  content: "\e588";
}
.slicon-vertical-flick:before {
  content: "\e589";
}
.slicon-arrow-1-1:before {
  content: "\e58a";
}
.slicon-arrow-2-1:before {
  content: "\e58b";
}
.slicon-arrow-3:before {
  content: "\e58c";
}
.slicon-arrow-4:before {
  content: "\e58d";
}
.slicon-arrow-5:before {
  content: "\e58e";
}
.slicon-arrow-6:before {
  content: "\e58f";
}
.slicon-arrow-7:before {
  content: "\e590";
}
.slicon-arrow-8:before {
  content: "\e591";
}
.slicon-arrow-9:before {
  content: "\e592";
}
.slicon-arrow-10:before {
  content: "\e593";
}
.slicon-arrow-11:before {
  content: "\e594";
}
.slicon-arrow-12:before {
  content: "\e595";
}
.slicon-arrow-13:before {
  content: "\e596";
}
.slicon-arrow-14:before {
  content: "\e597";
}
.slicon-arrow-15:before {
  content: "\e598";
}
.slicon-arrow-16:before {
  content: "\e599";
}
.slicon-arrow-17:before {
  content: "\e59a";
}
.slicon-arrow-18:before {
  content: "\e59b";
}
.slicon-arrow-19:before {
  content: "\e59c";
}
.slicon-arrow-20:before {
  content: "\e59d";
}
.slicon-arrow-21:before {
  content: "\e59e";
}
.slicon-arrow-22:before {
  content: "\e59f";
}
.slicon-arrow-23:before {
  content: "\e5a0";
}
.slicon-arrow-24:before {
  content: "\e5a1";
}
.slicon-arrow-25:before {
  content: "\e5a2";
}
.slicon-arrow-26:before {
  content: "\e5a3";
}
.slicon-arrow-27:before {
  content: "\e5a4";
}
.slicon-arrow-28:before {
  content: "\e5a5";
}
.slicon-arrow-29:before {
  content: "\e5a6";
}
.slicon-arrow-30:before {
  content: "\e5a7";
}
.slicon-arrow-31:before {
  content: "\e5a8";
}
.slicon-arrow-32:before {
  content: "\e5a9";
}
.slicon-arrow-33:before {
  content: "\e5aa";
}
.slicon-arrow-34:before {
  content: "\e5ab";
}
.slicon-arrow-35:before {
  content: "\e5ac";
}
.slicon-arrow-36:before {
  content: "\e5ad";
}
.slicon-arrow-37:before {
  content: "\e5ae";
}
.slicon-arrow-38:before {
  content: "\e5af";
}
.slicon-arrow-39:before {
  content: "\e5b0";
}
.slicon-arrow-40:before {
  content: "\e5b1";
}
.slicon-arrow-41:before {
  content: "\e5b2";
}
.slicon-arrow-42:before {
  content: "\e5b3";
}
.slicon-arrow-43:before {
  content: "\e5b4";
}
.slicon-arrow-44:before {
  content: "\e5b5";
}
.slicon-arrow-45:before {
  content: "\e5b6";
}
.slicon-arrow-46:before {
  content: "\e5b7";
}
.slicon-arrow-47:before {
  content: "\e5b8";
}
.slicon-arrow-48:before {
  content: "\e5b9";
}
.slicon-arrow-49:before {
  content: "\e5ba";
}
.slicon-arrow-50:before {
  content: "\e5bb";
}
.slicon-arrow-51:before {
  content: "\e5bc";
}
.slicon-arrow-52:before {
  content: "\e5bd";
}
.slicon-arrow-53:before {
  content: "\e5be";
}
.slicon-arrow-54:before {
  content: "\e5bf";
}
.slicon-arrow-55:before {
  content: "\e5c0";
}
.slicon-arrow-56:before {
  content: "\e5c1";
}
.slicon-arrow-57:before {
  content: "\e5c2";
}
.slicon-arrow-58:before {
  content: "\e5c3";
}
.slicon-arrow-59:before {
  content: "\e5c4";
}
.slicon-arrow-60:before {
  content: "\e5c5";
}
.slicon-arrow-61:before {
  content: "\e5c6";
}
.slicon-arrow-62:before {
  content: "\e5c7";
}
.slicon-arrow-63:before {
  content: "\e5c8";
}
.slicon-arrow-64:before {
  content: "\e5c9";
}
.slicon-arrow-65:before {
  content: "\e5ca";
}
.slicon-arrow-66:before {
  content: "\e5cb";
}
.slicon-arrow-67:before {
  content: "\e5cc";
}
.slicon-arrow-68:before {
  content: "\e5cd";
}
.slicon-arrow-69:before {
  content: "\e5ce";
}
.slicon-arrow-70:before {
  content: "\e5cf";
}
.slicon-arrow-71:before {
  content: "\e5d0";
}
.slicon-arrow-72:before {
  content: "\e5d1";
}
.slicon-arrow-circle-1:before {
  content: "\e5d2";
}
.slicon-arrow-circle-2:before {
  content: "\e5d3";
}
.slicon-arrow-circle-3:before {
  content: "\e5d4";
}
.slicon-arrow-circle-4:before {
  content: "\e5d5";
}
.slicon-arrow-circle-5:before {
  content: "\e5d6";
}
.slicon-arrow-circle-6:before {
  content: "\e5d7";
}
.slicon-arrow-circle-7:before {
  content: "\e5d8";
}
.slicon-arrow-circle-8:before {
  content: "\e5d9";
}
.slicon-arrow-circle-9:before {
  content: "\e5da";
}
.slicon-arrow-circle-10:before {
  content: "\e5db";
}
.slicon-arrow-circle-11:before {
  content: "\e5dc";
}
.slicon-arrow-circle-12:before {
  content: "\e5dd";
}
.slicon-arrow-circle-13:before {
  content: "\e5de";
}
.slicon-arrow-circle-14:before {
  content: "\e5df";
}
.slicon-arrow-circle-15:before {
  content: "\e5e0";
}
.slicon-arrow-circle-16:before {
  content: "\e5e1";
}
.slicon-arrow-circle-17:before {
  content: "\e5e2";
}
.slicon-arrow-circle-18:before {
  content: "\e5e3";
}
.slicon-arrow-circle-19:before {
  content: "\e5e4";
}
.slicon-arrow-circle-20:before {
  content: "\e5e5";
}
.slicon-arrow-circle-21:before {
  content: "\e5e6";
}
.slicon-arrow-circle-22:before {
  content: "\e5e7";
}
.slicon-arrow-circle-23:before {
  content: "\e5e8";
}
.slicon-arrow-circle-24:before {
  content: "\e5e9";
}
.slicon-arrow-circle-25:before {
  content: "\e5ea";
}
.slicon-arrow-circle-26:before {
  content: "\e5eb";
}
.slicon-arrow-circle-27:before {
  content: "\e5ec";
}
.slicon-arrow-circle-28:before {
  content: "\e5ed";
}
.slicon-arrow-circle-29:before {
  content: "\e5ee";
}
.slicon-arrow-circle-30:before {
  content: "\e5ef";
}
.slicon-arrow-delete-1:before {
  content: "\e5f0";
}
.slicon-arrow-delete-2:before {
  content: "\e5f1";
}
.slicon-arrow-dot-1:before {
  content: "\e5f2";
}
.slicon-arrow-dot-2:before {
  content: "\e5f3";
}
.slicon-arrow-dot-3:before {
  content: "\e5f4";
}
.slicon-arrow-dot-4:before {
  content: "\e5f5";
}
.slicon-arrow-dot-5:before {
  content: "\e5f6";
}
.slicon-arrow-dot-6:before {
  content: "\e5f7";
}
.slicon-arrow-rectangle-1:before {
  content: "\e5f8";
}
.slicon-arrow-rectangle-2:before {
  content: "\e5f9";
}
.slicon-arrow-rectangle-3:before {
  content: "\e5fa";
}
.slicon-arrow-rectangle-4:before {
  content: "\e5fb";
}
.slicon-arrow-rectangle-5:before {
  content: "\e5fc";
}
.slicon-arrow-rectangle-6:before {
  content: "\e5fd";
}
.slicon-arrow-rectangle-7:before {
  content: "\e5fe";
}
.slicon-arrow-rectangle-8:before {
  content: "\e5ff";
}
.slicon-arrow-rectangle-9:before {
  content: "\e600";
}
.slicon-arrow-rectangle-10:before {
  content: "\e601";
}
.slicon-arrow-rectangle-11:before {
  content: "\e602";
}
.slicon-arrow-rectangle-12:before {
  content: "\e603";
}
.slicon-arrow-rectangle-13:before {
  content: "\e604";
}
.slicon-arrow-rectangle-14:before {
  content: "\e605";
}
.slicon-arrow-rectangle-15:before {
  content: "\e606";
}
.slicon-arrow-rectangle-16:before {
  content: "\e607";
}
.slicon-arrow-rectangle-17:before {
  content: "\e608";
}
.slicon-arrow-rectangle-18:before {
  content: "\e609";
}
.slicon-arrow-rectangle-19:before {
  content: "\e60a";
}
.slicon-arrow-rectangle-20:before {
  content: "\e60b";
}
