.row-flex, .row-flex > div[class*='col-'] {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: flex;
    -webkit-flex:0 1 auto;
    -moz-flex:0 1 auto;
    -ms-flex:0 1 auto;
    flex:0 1 auto;
}
.row-flex-wrap {
	-webkit-flex-flow: row wrap;
	-moz-flex-flow: row wrap;
	-ms-flex-flow: row wrap;
    align-content: flex-start;
    -webkit-flex: 0;
    -moz-flex: 0;
    -ms-flex: 0;
    flex: 0;
}
.row-flex > div[class*='col-'], .container-flex > div[class*='col-'] {
    margin:-.2px; /* hack adjust for wrapping */
}
.container-flex > div[class*='col-'] > div,.row-flex > div[class*='col-'] > div {
	width:100%;
}
.flex-col {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: flex;
    -webkit-flex: 1 1 auto;
    -moz-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-flex-flow: column nowrap;
    -moz-flex-flow: column nowrap;
    -ms-flex-flow: column nowrap;
	flex-flow: column nowrap;
}
.flex-grow {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: flex;
    -webkit-flex: 2;
    -moz-flex: 2;
    -ms-flex: 2;
    flex: 2;
}
.flex-stretch {
    align-self: stretch;
    align-items: stretch;
    align-content: stretch;
}