#mainbottom {
    padding: 30px 0px;
    background-color: #49516b;
    margin-top: -75px;
    .module_container {
        padding: 80px 0px 40px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        h4 {
            text-align: center;
            color: white;
            font-size: 3rem;
            line-height: 1.1;
            word-spacing: 10px;
        }
    }
}