h1, h2, h3, h4, h5, h6 {
    color: $color-text;
    font-family: $font-headings;
    margin-top: 0;
    position: relative;
    line-height: 1;
    
    span.fa {
        margin-right: 5px;
    }
}
h1 {
    @include rem(font-size, 40px);
    font-weight: 400;
    margin-bottom: 10px;
}
h2 {
    @include rem(font-size, 30px);
    font-weight: 400;
    margin-bottom: 10px;
}
h3 {
    @include rem(font-size, 25px);
    font-weight: 400;
    margin-bottom: 10px;
}
h4 {
    @include rem(font-size, 20px);
    font-weight: 400;
    margin-bottom: 10px;
}
h5 {
    @include rem(font-size, 20px);
    font-weight: 400;
    margin-bottom: 10px;
}
h6 {
    @include rem(font-size, 15px);
    font-weight: 400;
    margin-bottom: 0px;
}
a {
    color: $color-link;
    text-decoration: none !important;
    @include transition(all, $transition-time, ease);
    &:hover {
        color: $color-link-hover;
        text-decoration: none;
    }
}
p {
    margin: 0 0 12px 0;
    @include rem(font-size, 14px);
    color: $color-text;
    line-height: 1.4;

    &:last-child {
      margin-bottom: 0;
    }
}
ol, ul {
    @include rem(font-size, 14px);
    line-height: 1.1;
    list-style-position: outside;
    margin-bottom: 18px;
    padding: 0 0 0 1em;
}
:focus {
    outline: none;
    box-shadow: none;
}