#showcase-wrapper {
    background-image: url(/images/slide-01.jpg);
    background-size: cover;
    background-position: top center;
    padding: 180px 0px 120px;
    @media (max-width: 767px) {
        padding: 60px 0px 120px;
    }
    #showcase {
        .module_container {
            background-color: #cb1f81;
            padding: 40px;
            background-image: url(/images/h1-bg.png);
            background-position: right -300px top 30px;
            background-repeat: no-repeat;
            h1 {
                font-size: 2.4rem;
                line-height: 1.4;
                letter-spacing: 0px;
                margin: 0px;
                text-align: center;
                color: white;
            }
        }
    }
}