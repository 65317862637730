#content {
    .logout-page {
        padding: 30px 0 60px;
        overflow: hidden;
    }
    .login-page {
        padding: 30px 0 60px;
        overflow: hidden;
        input {
            height: 50px;
            border: 0px;
            padding: 15px;
            width: 100%;
            margin-top: 10px;
            color: white;
            background-color: #8c92a0;

            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: white;
            }
            &::-moz-placeholder { /* Firefox 19+ */
                color: white;
            }
            &:-ms-input-placeholder { /* IE 10+ */
                color: white;
            }
            &:-moz-placeholder { /* Firefox 18- */
                color: white;
            }
        }
        button {
            height: 50px;
            padding: 14px 30px;
            background-color: #49516b;
            border: 0px;
            float: right;
            margin-top: 10px;
        }
    }
    .medlem {
        border-bottom: 1px solid #f2f2f2;
        padding: 20px 0px;
        &:nth-last-child(1) {
            border: 0px;
        }
        .info {
            img {
                width: 100%;
                margin-top: 20px;
                @media (max-width: 767px) {
                    margin-bottom: 20px;
                }
            }
            h2 {
                font-size:1.6rem;
                color:#cb1f81;
            }
            span {
                display:block;
            }
        }
        .cont {
            h3 {
                font-size:1.6rem;
                color:#cb1f81;
            }
        }
    }
    .news {
        h2 {
            font-size: 2rem;
            font-weight: 400;
            margin-bottom: 10px;
            color: #cb1f81;
            margin-top: 10px;
        }

        .cont {
            margin-bottom: 20px;
            padding-bottom: 10px;
            padding-top: 10px;
            border-bottom: 1px solid #f2f2f2;
        }
    }
    .item-page {
        padding: 20px 0px;
        h2 {
            color:#cb1f81;
        }
        h3 {
            color:#cb1f81;
        }
        h4 {
            color:#cb1f81;
        }
    }
    
    .llc.default {
        .schedule {
            h3 {
                color: $llc-default-main;
                font-weight: 600;
            }
        }
        h2 {
            color: $llc-default-main;
        }
        h3 {
            color: $llc-default-fourth;
        }
        .title {
            background-color: $llc-default-main;
        }
        .sidebar {
            li {
                background-color: $llc-default-main;
                &:hover {
                    background-color: $llc-default-second;
                }
                &:nth-last-child(1) {
                    background-color: $llc-default-third;
                    &:hover {
                        background-color:$llc-default-second;
                    }
                }
                &:nth-last-child(2) {
                    background-color: $llc-default-fourth;
                    &:hover {
                        background-color:$llc-default-fourth;
                    }
                }
                &:nth-last-child(3) {
                    background-color: $llc-default-third;
                    &:hover {
                        background-color:$llc-default-second;
                    }
                }
            }
            li.active {
                background-color: $llc-default-second;
            }
        }
        #llcForm {
            background-color: $llc-default-main;
            button {
                background-color: $llc-default-third;
                &:hover {
                    background-color: $llc-default-second;
                }
            }
        }
    }
    .llc.second {
        .schedule {
            h3 {
                color: $llc-second-main;
                font-weight: 600;
            }
        }
        h2 {
            color: $llc-second-main;
        }
        h3 {
            color: $llc-second-fourth;
        }
        .title {
            background-color: $llc-second-main;
        }
        .sidebar {
            li {
                background-color: $llc-second-main;
                &:hover {
                    background-color: $llc-second-second;
                }
                &:nth-last-child(1) {
                    background-color: $llc-second-third;
                    &:hover {
                        background-color:$llc-second-second;
                    }
                }
                &:nth-last-child(2) {
                    background-color: $llc-second-fourth;
                    &:hover {
                        background-color:$llc-second-fourth;
                    }
                }
                &:nth-last-child(3) {
                    background-color: $llc-second-third;
                    &:hover {
                        background-color:$llc-second-second;
                    }
                }
            }
            li.active {
                background-color: $llc-second-second;
            }
        }
        #llcForm {
            background-color: $llc-second-main;
            button {
                background-color: $llc-second-third;
                &:hover {
                    background-color: $llc-second-second;
                }
            }
        }
    }
    .llc.third {
        .schedule {
            h3 {
                color: $llc-third-main;
                font-weight: 600;
            }
        }
        h2 {
            color: $llc-third-main;
        }
        h3 {
            color: $llc-third-fourth;
        }
        .title {
            background-color: $llc-third-main;
        }
        .sidebar {
            li {
                background-color: $llc-third-main;
                &:hover {
                    background-color: $llc-third-second;
                }
                &:nth-last-child(1) {
                    background-color: $llc-third-third;
                    &:hover {
                        background-color:$llc-third-second;
                    }
                }
                &:nth-last-child(2) {
                    background-color: $llc-third-fourth;
                    &:hover {
                        background-color:$llc-third-fourth;
                    }
                }
                &:nth-last-child(3) {
                    background-color: $llc-third-third;
                    &:hover {
                        background-color:$llc-third-second;
                    }
                }
            }
            li.active {
                background-color: $llc-third-second;
            }
        }
        #llcForm {
            background-color: $llc-third-main;
            button {
                background-color: $llc-third-third;
                &:hover {
                    background-color: $llc-third-second;
                }
            }
        }
    }
    
    .llc{

        #llcForm {
            padding: 30px;
            input {
                height: 50px;
                border: 0px;
                padding: 15px;
                width: 100%;
                margin-bottom: 10px;
                color:black;
            }
            .file {
                input {
                    color:white;
                }
            }
            textarea {
                width: 100%;
                padding: 15px;
                resize: none;
                border: 0px;
                margin-bottom: 5px;
                height: 120px;
                color:black;
            }
            button {
                height: 50px;
                padding: 14px 30px;
                border:0px;
                float:right;
                color:white
            }
        }
        h3 {
            font-size: 16px;
            font-weight: normal;
            line-height: 20px;
        }
        .llc-content {
            margin-bottom: 30px;
            overflow: hidden;
            ul {
                list-style: none;
                padding: 0px;
                margin: 0px 0px 18px;
                li {
                    line-height: 1.4;
                    display:block;
                    &:before {
                        font-family: fontawesome;
                        content: "\f105";
                        padding-right: 10px;
                        font-size: 9px;
                        color:#cb1f81;
                    }
                }
            }
            &.electedofficials {
                .person {
                    background: #efefef;
                    margin-bottom: 30px;
                    padding: 20px;
                    .info {
                        padding: 10px 0px 0px;
                        h3 {
                            font-size: 14px;
                            margin-bottom: 0px;
                            font-weight: 600;
                        }
                        ul {
                            word-break: break-all;
                            margin-top: 10px;
                            margin-bottom: 0px;
                        }
                    }
                    .image {
                        text-align: center;
                    }
                }
            }
            &.leaders {
                margin-top: 20px;
                .person {
                    background: #efefef;
                    margin-bottom: 30px;
                    padding: 20px;
                    .info {
                        padding: 10px 0px 0px;
                        h3 {
                            font-size: 14px;
                            margin-bottom: 0px;
                            font-weight: 600;
                        }
                        ul {
                            word-break: break-all;
                            margin-top: 10px;
                            margin-bottom: 0px;
                        }
                    }
                    .image {
                        text-align: center;
                    }
                }
            }
            &.ds {
                margin-top: 20px;
                .person {
                    background: #efefef;
                    margin-bottom: 30px;
                    padding: 20px;
                    .info {
                        padding: 10px 0px 0px;
                        h3 {
                            font-size: 14px;
                            margin-bottom: 0px;
                            font-weight: 600;
                        }
                        ul {
                            word-break: break-all;
                            margin-top: 10px;
                            margin-bottom: 0px;
                        }
                    }
                    .image {
                        text-align: center;
                    }
                    .district {
                        h3 {
                            color: #cb1f81;
                        }
                        text-align: center;
                        margin-bottom: 10px;
                    }
                }
            }
            #login-form h2 {
                font-size: 22px;
            }
        }
        .map {
            text-align: center;
        }
        .login-form {
            input {
                height: 50px;
                border: 0px;
                padding: 15px;
                width: 100%;
                margin-top: 10px;
                color: white;
                background-color: #8c92a0;
                
                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    color: white;
                }
                &::-moz-placeholder { /* Firefox 19+ */
                    color: white;
                }
                &:-ms-input-placeholder { /* IE 10+ */
                    color: white;
                }
                &:-moz-placeholder { /* Firefox 18- */
                    color: white;
                }
            }
            button {
                height: 50px;
                padding: 14px 30px;
                background-color: #49516b;
                border: 0px;
                float: right;
                margin-top: 10px;
            }
        }
        .info {
            padding: 20px 15px;
            h2 {
                color: #cb1f81;
                font-size: 2.5rem;
                margin-bottom: 20px;
            }
            #llc-search {
                height: 54px;
                border: 0px;
                padding: 15px;
                width: 100%;
                margin-top: 10px;
                color: black;
                background-color: #f2f2f2;
                margin-bottom: 20px;
            }
            .panel-title {
                color: #cb1f81;
                font-size: 2rem;
            }
            .panel-collapse {
                height: auto !important;
            }
            .each {
                list-style: none;
                padding: 0px;
                margin: 0px 0px 10px;
                li{
                    display: inline-block;
                    font-weight:300;
                    font-size:1.2rem;
                }
                li.clear{
                    display: block;
                }
                li.group_address:after {
                    content: ',';
                }

                li.cat,li.group_nr,li.name {
                    font-weight:600;
                    font-size:1.4rem;
                }
            }
        }
    }
    .search {
        h1 {
            margin: 30px 0px;
            font-size: 3rem;
        }
        .each{ 
            &.llc {
                padding: 30px;
                background-color: #eeeeee;
                margin-bottom: 30px;
            }
        }
        .pagination {
            li {
                display: inline-block;
                padding: 5px 10px;
            }
        }
    }
    .singlearticle table {
        width: 100% !important;
    }
}