/*General*/
a.module_link {
    display: block;
    
    > .module_container {
        background-color: $color-lightest;
        color: $color-text;
        padding: 30px;
        @include transition(all, $transition-time, ease);
    }
    &:hover > .module_container {
        background-color: $color-light;
        color: $color-text;
    }
    
    &[class^='hvr-icon-'], &[class*=' hvr-icon-'] {
        padding-right: 0;
        &:before {
            top: 50%;
            font-size: 20px;
            line-height: 20px;
            height: 20px;
            margin-top: -10px;
            right: 30px;
            color: $color-text;
        }
        > .module_container {
            padding-right: 60px;
        }
    }
}