/*=================== RESPONSIVE RULES ======================*/

/* X-Large - Desktop */
@media (min-width: 1430px) {
    
}

/* Large - Laptop */
@media (max-width: 1429px) {
  
}

/* Medium - Tablet Landscape (1024px) */
@media (max-width: 1199px) {
  
}

/* Small - Tablet Portrait (768px) */
@media (max-width: 991px) {

}

/* X-Small - Mobile (320px / 375px) */
@media (max-width: 767px) {
    body.option-com_llc #content .content-inner {
		display: -webkit-box;
		display: -moz-box;
		display: box;
		-webkit-box-orient: vertical;
		-moz-box-orient: vertical;
		box-orient: vertical;
	}
    body.option-com_llc #content .content-inner #component {
        -webkit-box-ordinal-group: 1;
        -moz-box-ordinal-group: 1;
        -ms-flex-order: 1;
        -webkit-order: 1;
        order: 1;
    }
    body.option-com_llc #content .content-inner #aside-left {
        -webkit-box-ordinal-group: 2;  
        -moz-box-ordinal-group: 2;     
        -ms-flex-order: 2;     
        -webkit-order: 2;  
        order: 2;
    }
    body.option-com_llc #content .content-inner #aside-right {
        -webkit-box-ordinal-group: 3;  
        -moz-box-ordinal-group: 3;     
        -ms-flex-order: 3;     
        -webkit-order: 3;  
        order: 3;
    }
}