#header {
    z-index: 2;
    position: relative;
    background-color: $color-second;
    color:white;
    .menu {
        .active {
            > a{
                color: $color-link-hover;
            }
        }
        padding: 0px;
        margin: 0px;
        position: relative;
        @media (min-width: 767px) {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: flex;
        }
        width: 100%;
        > li {
            @media (min-width: 767px) {
                display: inline-block;
                -webkit-flex: 1 1 auto;
                -moz-flex: 1 1 auto;
                -ms-flex: 1 1 auto;
                flex: 1 1 auto;
            }
            @media (min-width: 767px) {
                &:before {
                    content:'';
                    background-color: darken($color-second, 10%);
                    width:1px;
                    height:60%;
                    display:block;
                    position:absolute;
                    top:20%;
                    left:0;
                }
            }
            &:nth-child(1) {
                &:before {
                    display:none;
                }
            }
            a {
                @include rem(font-size, 14px);
                @media (max-width: 991px) {
                    @include rem(font-size, 12px);
                }
                @media (max-width: 767px) {
                    @include rem(font-size, 14px);
                }
                padding: 12px 10px;
                word-break: break-word;
                @media (min-width: 767px) {
                    display: -webkit-box;
                    display: -moz-box;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: -moz-flex;
                    display: -ms-flex;
                    display: flex;
                }
                &:hover {
                    background-color: inherit;
                }
            }
            > a {
                
                justify-content: center;
                align-items: center;
                align-content: center;
                align-self: center;
            }
        }
        @media (min-width: 767px) {
            .deeper {
                > ul {
                    display: none;
                    position: absolute;
                    background-color: #39415b;
                    left: 0;
                    width: 100%;
                    min-width: 200px;
                    list-style: none;
                    padding: 0px;
                    margin: 0px;
                    > li {
                        @include transition(all, $transition-time, ease);
                        &:hover {
                            a {
                                color:inherit;
                            }
                            background-color: $color-second;

                        }
                    }
                }
                &:hover {
                    > ul {
                        display: block;
                    }
                }
            }
        }
        @media (max-width: 767px) {
            .deeper {
                > ul {
                    width: 100%;
                    list-style: none;
                    padding: 0px;
                    margin: 0px;
                    > li {
                        a {
                            &:before{
                                content: '\e5cd';
                                font-family:'Streamline';
                                font-size:0.7rem;
                                margin-right: 12px;
                                margin-left: -6px;
                                vertical-align:bottom;
                                line-height: 15px;
                                color: white;
                            }
                            display: block;
                        }
                    }
                }
            }
        }
    }
}